// Assign the `[line-clamp]` attribute to any element to make its text truncate with an ellipsis:
//
//     <div line-clamp="2">
//        Super long text will be clamped after 2 lines.
//     </div>
//
// The weird CSS properties are actually supported by many browsers, despite being prefixed with "-webkit".
// See [CSS-tricks article](https://css-tricks.com/line-clampin/).
//
// There is also `FormatHelper#line_clamp` to help applying this attribute to a block of HTML.
//
[line-clamp]
  display: -webkit-box
  -webkit-box-orient: vertical
  overflow: hidden

[line-clamp='2']
  -webkit-line-clamp: 2

[line-clamp='3']
  -webkit-line-clamp: 3
