@import ../../environment

.alert
  p:not(:last-child)
    margin-bottom: var(--spacer-16)

  --bs-alert-color: $white
  --bs-alert-bg: #{$brand-slate}
  --bs-alert-border-color: #{$brand-slate}
  --bs-alert-link-color: $white

  &.alert-success
    --bs-alert-color: $white
    --bs-alert-bg: #{$ui-success}
    --bs-alert-border-color: #{$ui-success}
    --bs-alert-link-color: $white
