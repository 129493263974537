@import ../environment

.section-header
  --section-header-color: var(--interaction-secondary-disabled)

  color: var(--section-header-color)
  margin: var(--spacer-24) 0 var(--spacer-12) 0
  +copy
  letter-spacing: var(--letter-spacing-copy-wide)
  font-weight: 400
