@import environment
@import ../fonts/open_sans
@import ../fonts/gilroy
@import config/base

html
  font-size: var(--font-size-copy) // anchor rems

body
  min-height: 100vh
  overflow-y: hidden

  background-color: var(--white-level-0)

  // Fix rendering of bold fonts on Mac OS X (does not affect Chrome)
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

  &.-loading
    &,
    & *
      cursor: wait !important

  &.-intro-animation-running
    overflow: hidden
    +scrollbar-hidden
