@import ../environment

.page-header
  margin-bottom: var(--spacer-32)

  +media-breakpoint-up(lg)
    display: grid
    grid-template-columns: minmax(0, 1fr) auto auto
    grid-template-rows: auto
    grid-template-areas: "title info actions" "subtitle . ."
    align-items: start

    +within-modal
      grid-template-areas: "title actions" "info info" "subtitle ."

.page-header--title
  grid-area: title

  margin: 0
  word-wrap: break-word

.page-header--subtitle
  grid-area: subtitle
  color: var(--interaction-secondary-disabled)

.page-header--info
  grid-area: info

  +copy-small
  color: var(--interaction-primary-disabled)

  +within-modal
    padding-top: 0
    text-align: inherit

.page-header--actions
  grid-area: actions

  display: flex
  flex-wrap: wrap
  gap: var(--spacer-8)
  align-items: center

  margin: var(--spacer-8) 0 0 0

  +media-breakpoint-up(lg)
    margin: 0 0 0 var(--spacer-12)
