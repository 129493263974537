@import ../variables

\:root
   --lca-tile-blur-radius: 30px
   --lca-tile-min-width: 334px
   // the width for the largest button within a tile is about 240px (Freeze & copy data).
   // to prevent elements from breaking out of the tile, we have to give the tile enough width.
   // this is why we use the same min-width for mobile as for desktop
   --lca-tile-min-width-mobile: 334px
   --lca-tile-max-width: 630px
   --lca-tile-details-max-width: 180px

   --kpi-tile-min-width: 250px
   --kpi-tile-max-width: 370px
