@import ../../environment

::-moz-selection
  background-color: var(--ui-highlight-primary)
  color: #fff
::selection
  background-color: var(--ui-highlight-primary)
  color: #fff
img::selection, iframe::selection
  background-color: rgba(var(--ui-highlight-primary-rgb), 0.6)
