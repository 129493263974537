@import ../environment

.kpi-tile-title
  display: grid
  grid-template-areas: "main-title" "subtitle"
  justify-items: center

.kpi-tile-title--collapsed-title,
.kpi-tile-title--expanded-title
  +button-typography
  grid-area: main-title
  text-align: center
  line-height: 110%

.kpi-tile-title--collapsed-title
  +uppercase

.kpi-tile-title--subtitle
  grid-area: subtitle
  font-weight: 600
  font-size: var(--font-size-copy-mini)

+within-collapsed-kpi-tile
  .kpi-tile-title--expanded-title
    display: none

+within-expanded-kpi-tile
  .kpi-tile-title--collapsed-title,
  .kpi-tile-title--subtitle
    display: none
