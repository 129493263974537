\:root
  --border-radius: 0
  --multiselect-item-border-radius: 16px
  --tile-border-radius: 20px

  --border-width-default: 1px
  --border-width-active: 3px
  --outline-width-default: 1px
  --outline-offset-focus: 2px
  --max-border-radius: 50%

  --tab-group-border-width: 0
  --tab-group-border-width-bottom: 3px
  --tab-group-border-color: var(--white)
  
  --kpi-tile-border-radius: 5px
  --kpi-active-tile-border-width: 2px
