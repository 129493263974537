.searchbar {
  min-width: var(--input-min-width);
  max-width: var(--searchbar-max-width);
  background: transparent;
}
.searchbar .form-control {
  background: transparent;
}
.searchbar .input-group-text {
  background: transparent;
  height: var(--input-height);
}
.searchbar.-stretched {
  max-width: 100%;
}

.searchbar--search-icon {
  --icon-color: var(--white);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbInNlYXJjaGJhci5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBRUY7RUFDRTtFQUNBOztBQUVGO0VBQ0U7OztBQUVKO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuc2VhcmNoYmFyXG4gIG1pbi13aWR0aDogdmFyKC0taW5wdXQtbWluLXdpZHRoKVxuICBtYXgtd2lkdGg6IHZhcigtLXNlYXJjaGJhci1tYXgtd2lkdGgpXG4gIGJhY2tncm91bmQ6IHRyYW5zcGFyZW50XG5cbiAgLmZvcm0tY29udHJvbFxuICAgIGJhY2tncm91bmQ6IHRyYW5zcGFyZW50XG5cbiAgLmlucHV0LWdyb3VwLXRleHRcbiAgICBiYWNrZ3JvdW5kOiB0cmFuc3BhcmVudFxuICAgIGhlaWdodDogdmFyKC0taW5wdXQtaGVpZ2h0KVxuXG4gICYuLXN0cmV0Y2hlZFxuICAgIG1heC13aWR0aDogMTAwJVxuXG4uc2VhcmNoYmFyLS1zZWFyY2gtaWNvblxuICAtLWljb24tY29sb3I6IHZhcigtLXdoaXRlKVxuIl19 */