.reference-impact-chooser {
  min-width: var(--reference-impact-input-min-width);
}

.reference-impact-chooser--visible-input:not([disabled]) {
  cursor: pointer;
}

.reference-impact-chooser--reset {
  padding-left: 0;
  display: none;
  align-self: end;
  color: var(--interaction-primary-active);
}
.-with-value .reference-impact-chooser--reset {
  display: block;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbInJlZmVyZW5jZV9pbXBhY3RfY2hvb3Nlci5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7OztBQUdBO0VBQ0U7OztBQUVKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5yZWZlcmVuY2UtaW1wYWN0LWNob29zZXJcbiAgbWluLXdpZHRoOiB2YXIoLS1yZWZlcmVuY2UtaW1wYWN0LWlucHV0LW1pbi13aWR0aClcblxuLnJlZmVyZW5jZS1pbXBhY3QtY2hvb3Nlci0tdmlzaWJsZS1pbnB1dFxuICAmOm5vdChbZGlzYWJsZWRdKVxuICAgIGN1cnNvcjogcG9pbnRlclxuXG4ucmVmZXJlbmNlLWltcGFjdC1jaG9vc2VyLS1yZXNldFxuICBwYWRkaW5nLWxlZnQ6IDBcbiAgZGlzcGxheTogbm9uZVxuICBhbGlnbi1zZWxmOiBlbmRcbiAgY29sb3I6IHZhcigtLWludGVyYWN0aW9uLXByaW1hcnktYWN0aXZlKVxuXG4gIC4td2l0aC12YWx1ZSAmXG4gICAgZGlzcGxheTogYmxvY2tcbiJdfQ== */