@import ../environment

.lca-status
  display: flex
  align-items: center

.lca-status--icon
  .lca-status.-absent &
    color: var(--white)
    +icon(lca-stage-unsaved, $size: 20)

  .lca-status.-in-progress &
    color: var(--white)
    +icon(lca-in-progress, $size: 20)

  .lca-status.-complete &
    color: var(--ui-success)
    +icon(lca-stage-approved, $size: 20)

.lca-status--text
  .lca-status--icon + &
    margin-left: var(--spacer-12)
