.input-group {
  flex-wrap: nowrap;
  min-width: var(--input-min-width);
  width: auto;
}
.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: auto;
}
.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: auto;
}
.input-group.is-invalid {
  flex-wrap: wrap;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbXBvbmVudHMvYm9vdHN0cmFwX2V4dCIsInNvdXJjZXMiOlsiaW5wdXRfZ3JvdXAuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTs7QUFFQTtFQUdFOztBQUVGO0VBTUU7O0FBRUY7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5pbnB1dC1ncm91cFxuICBmbGV4LXdyYXA6IG5vd3JhcFxuICBtaW4td2lkdGg6IHZhcigtLWlucHV0LW1pbi13aWR0aClcbiAgd2lkdGg6IGF1dG9cblxuICAmID4gLmZvcm0tY29udHJvbDpmb2N1cyxcbiAgJiA+IC5mb3JtLXNlbGVjdDpmb2N1cyxcbiAgJiA+IC5mb3JtLWZsb2F0aW5nOmZvY3VzLXdpdGhpblxuICAgIHotaW5kZXg6IGF1dG9cblxuICAud2FzLXZhbGlkYXRlZCAmID4gLmZvcm0tY29udHJvbDpub3QoOmZvY3VzKTppbnZhbGlkLFxuICAmID4gLmZvcm0tY29udHJvbDpub3QoOmZvY3VzKS5pcy1pbnZhbGlkLFxuICAud2FzLXZhbGlkYXRlZCAmID4gLmZvcm0tc2VsZWN0Om5vdCg6Zm9jdXMpOmludmFsaWQsXG4gICYgPiAuZm9ybS1zZWxlY3Q6bm90KDpmb2N1cykuaXMtaW52YWxpZCxcbiAgLndhcy12YWxpZGF0ZWQgJiA+IC5mb3JtLWZsb2F0aW5nOm5vdCg6Zm9jdXMtd2l0aGluKTppbnZhbGlkLFxuICAmID4gLmZvcm0tZmxvYXRpbmc6bm90KDpmb2N1cy13aXRoaW4pLmlzLWludmFsaWRcbiAgICB6LWluZGV4OiBhdXRvXG5cbiAgJi5pcy1pbnZhbGlkXG4gICAgZmxleC13cmFwOiB3cmFwXG4iXX0= */