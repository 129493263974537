@import ../environment

.sidebar-menu
  display: flex
  flex-direction: column

  margin: 0
  padding: 0
  row-gap: var(--spacer-32)

  list-style: none
  -ms-overflow-style: none  /* IE and Edge */
  overflow-y: scroll
  +scrollbar-width(none)  /* Firefox */

  &::-webkit-scrollbar
    display: none

  &.-is-main-menu
    padding: 0 var(--sidebar-inline-padding) var(--spacer-24)

    +on-mobile-screens
      height: 100%
      row-gap: var(--spacer-20)

  &.-is-bottom-menu
    row-gap: var(--spacer-8)
