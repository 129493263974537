@import ../environment

.action-bar
  gap: var(--spacer-16)
  margin-top: var(--spacer-48)

  +on-mobile-screens
    margin-top: var(--spacer-24)

  display: flex
  flex-wrap: wrap
  justify-content: flex-end

  up-modal:not([size="super-large"]) &
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: 1fr

    & > .btn,
    & > .button-wrapper
      --button-width-min: 100%
      &:last-child:nth-child(odd)
        grid-column: 1 / -1 // Always stretch the last action to full width

    +media-breakpoint-up(md)
      grid-template-columns: repeat(2, 1fr)

  // If there are 1-2 buttons in a super-large modal, they are aligned as if they weren't in a modal
  // Then they are grouped in rows of three buttons
  up-modal[size="super-large"] &
    &:not(.-with-action-count-1):not(.-with-action-count-2)
      display: grid
      grid-template-columns: 1fr
      grid-template-rows: 1fr

      & > .btn,
      & > .button-wrapper
        --button-width-min: 100%
        +on-mobile-screens
          grid-column: 1 / -1

      +media-breakpoint-up(md)
        grid-template-columns: repeat(3, 1fr)
        &.-with-action-count-4
          grid-template-columns: repeat(2, 1fr)
