@import ../environment

.units-field
  display: grid
  grid-template-columns: 1fr max-content
  min-width: var(--input-min-width)

.units-field--input
  input.form-control
    // Allow the input to shrink, it still has a min-width,
    // which is determined by the min-width of .units-field minus the unit select
    min-width: 0

.units-field--selection
  label
    // combined value + unit fields should not have two labels
    display: none

    +only-in-tests
      display: flex

  .ts-control
    input
      min-width: 90px !important // Make the input just wide enough, so the "Unit" placeholder fits

  .form-group.-has-selection
    .ts-control
      input
        // Dont show the input, when there is already a unit selected, because there is litte room.
        // Can´t set the width directly, because it gets overwritten by tom select per js on type.
        // !important, because we need to overwrite tom select styles
        max-width: 0px !important
        min-width: 0px !important
        margin: 0

.units-field--input:has(.is-invalid), .units-field--selection:has(.is-invalid)
  .form-group
    grid-template-areas: "label" "input" "validation"
    grid-auto-rows: minmax(var(--spacer-32), auto) 1fr 1fr
