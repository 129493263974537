@import ../../environment

\:root
  --bs-body-text-align: left

  --link-decoration: #{$--link-decoration}
  --link-hover-decoration: #{$--link-hover-decoration}

  --box-shadow: #{$box-shadow}
  --box-shadow-sm: #{$box-shadow-sm}
  --box-shadow-lg: #{$box-shadow-lg}

  --input-transition: #{$input-transition}
  --input-btn-focus-box-shadow: #{$input-btn-focus-box-shadow}

  --nav-link-padding-x: #{$nav-link-padding-x}
  --nav-link-padding-y: #{$nav-link-padding-y}

  --form-group-spacer: #{$form-group-spacer}
  --grid-gutter-width: #{$grid-gutter-width}
