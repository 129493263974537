@import '../environment'

.help-text
  margin-block: var(--spacer-16)
  color: var(--ui-secondary-disabled)
  font-style: italic

  &.-for-subheading
    margin-top: 0
    font-style: normal
    color: var(--interaction-secondary-disabled)
    +copy-small
