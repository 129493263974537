=line-clamp($lines)
  +break-word
  -webkit-line-clamp: $lines
  display: -webkit-box

=break-word
  overflow: hidden
  overflow-wrap: break-word
  -webkit-box-orient: vertical

=truncate-text-overflow
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

= truncate-text-overflow-x
  overflow-x: hidden
  text-overflow: ellipsis
  white-space: nowrap
