@import ../environment

.sidebar-menu-item
  height: 36px // The font family for some reason creates a larger height that we need to fix
  padding-block: var(--spacer-8)

  font-family: var(--headings-font-family-bolder)
  letter-spacing: 2px
  +uppercase

  a
    display: inline-block
    width: 100%

    +truncate-text-overflow-x

    .icon.-spacer
      margin-inline-end: var(--spacer-20)

    +when-sidebar-collapsed
      .icon.-spacer
        margin: 0

    +active-hover
      color: var(--link-hover-color)

    &.up-current
      color: var(--interaction-primary-active)

    +when-sidebar-collapsed
      &::after
        display: none

.sidebar-menu-item--text
  +when-sidebar-collapsed
    display: none
