@import '../environment'

.form-group.-for-quill-editor
  .form-label,
  .form-control
    display: none

  .ql-blank:before
    font-style: normal
    color: var(--ui-secondary)

  .ql-toolbar
    padding: 0 10px
    border: 2px solid var(--input-background-color)
    background: #161616
    button
      padding: 0
      height: 18px

  .ql-container
    height: 350px
    font-family: var(--font-family-copy)

  .ql-editor
    min-height: 150px
    background-color: var(--input-background-color)
    font-size: var(--input-font-size)
    line-height: var(--input-line-height)
    padding: 1rem var(--input-horizontal-padding) var(--input-padding-bottom)

  .ql-stroke
    stroke: currentColor !important
    stroke-width: 1
  .ql-fill
    fill: currentColor !important

  button
    color: var(--interaction-primary-default)
    &:hover,
    &:focus
      outline: 0
      color: var(--interaction-primary-hover)

  button.ql-active
    color: var(--interaction-primary-active)

// we need to increase the specificity in order to
// override the default styles of the quill editor
// and show the colored border on focus
.ql-container.ql-container
  +only-bottom-border