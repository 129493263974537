@import ../environment

.icon-button
  display: flex
  gap: var(--spacer-16)

  .icon-button--btn
    display: flex
    align-items: center
    justify-content: center
    gap: var(--spacer-16)

.icon-button--icon
  width: var(--small-icon-size)
  height: var(--small-icon-size)
  color: var(--bs-btn-color)
  stroke: currentColor

.icon-button--label
  // The label text seems to have some magic padding-bottom,
  // I was unable to vertically center it better than this :-/
  --vertical-label-offset: 2px
  transform: translateY(var(--vertical-label-offset))
