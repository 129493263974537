@import ../variables/breakpoints

=breakpoint-up($name)
  $min-width: map-get($breakpoints, $name)
  @media (min-width: $min-width)
    @content

=breakpoint-down($name)
  $min-width: map-get($breakpoints, $name)
  @media (max-width: $min-width - 0.02px) // like Bootstrap: because max-width is inclusive, and 0.02px for Safari: https://bugs.webkit.org/show_bug.cgi?id=178261
    @content

=on-mobile-screens
  +breakpoint-down(md)
    @content

=on-non-mobile-screens
  +breakpoint-up(md)
    @content

=on-tablet-screens
  +breakpoint-up(md)
    +breakpoint-down(lg)
      @content

.show-on-mobile
  +on-non-mobile-screens
    display: none

.hide-on-mobile
  +on-mobile-screens
    display: none
