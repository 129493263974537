@import ../../environment

h1, .h1
  +h1

h2, .h2
  +h2

h3, .h3
  +h3

h4, .h4
  +h4

small, .small
  +copy-small

strong, b
  +bold

th, dt, td
  +copy-mini
  letter-spacing: var(--letter-spacing-copy-medium)

th, dt
  color: var(--ui-secondary)
  --table-color: var(--ui-secondary)

p
  margin-bottom: 1.5em
  &:last-child
    margin-bottom: 0
