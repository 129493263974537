@font-face
  font-family: 'Gilroy'
  font-display: swap
  font-style: normal
  font-weight: 400
  src: local(''), url('/app/app/assets/fonts/./gilroy/Gilroy-Regular.ttf') format('truetype')

@font-face
  font-family: 'Gilroy'
  font-display: swap
  font-style: italic
  font-weight: 400
  src: local(''), url('/app/app/assets/fonts/./gilroy/Gilroy-RegularItalic.ttf') format('truetype')

@font-face
  font-family: 'Gilroy Semibold'
  font-display: swap
  font-style: normal
  font-weight: 400
  src: local(''), url('/app/app/assets/fonts/./gilroy/Gilroy-SemiBold.ttf') format('truetype')

@font-face
  font-family: 'Gilroy Semibold'
  font-display: swap
  font-style: italic
  font-weight: 400
  src: local(''), url('/app/app/assets/fonts/./gilroy/Gilroy-SemiBoldItalic.ttf') format('truetype')

@font-face
  font-family: 'Gilroy'
  font-display: swap
  font-style: normal
  font-weight: 700
  src: local(''), url('/app/app/assets/fonts/./gilroy/Gilroy-Bold.woff2') format('woff2')

@font-face
  font-family: 'Gilroy'
  font-display: swap
  font-style: normal
  font-weight: 900
  src: local(''), url('/app/app/assets/fonts/./gilroy/Gilroy-Black.woff2') format('woff2')
