@import ../environment

.sidebar-root--logo
  --height: 30px
  height: var(--height)
  width: calc(var(--height) * var(--aspect-ratio))

  +when-sidebar-collapsed
    &.-for-expanded
      display: none

  +when-sidebar-expanded
    &.-for-collapsed
      display: none
