.stage-activity
  position: relative
  padding: 0 0 30px 60px

  &:before
    content: ""
    position: absolute
    left: 25px
    top: 0
    bottom: 0
    width: 1px
    background: var(--ui-highlight-primary-60)

  &:last-of-type
    &:before
      display: none

.stage-activity--anchor
  position: relative
  top: -20px

.stage-activity--icon
  display: block
  position: relative
  z-index: 1
  width: 50px
  height: 50px
  border-radius: 50%
  text-align: center
  padding: 12px 0
  float: left
  margin-left: -60px
  background: var(--ui-highlight-primary)


.stage-activity--content
  padding: 15px
  border-radius: 10px
  background: var(--background-level-1)
