.empty-content {
  --icon-margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-32);
  text-align: center;
}
.empty-content.-small {
  --icon-margin: 10px;
  gap: var(--spacer-12);
}

.empty-content--icon {
  --icon-margin-top: var(--icon-margin);
  --icon-margin-bottom: var(--icon-margin);
  --icon-color: var(--white);
}

.empty-content--title {
  color: var(--ui-primary);
  margin: 0;
}
.empty-content.-small .empty-content--title {
  font-size: var(--font-size-copy-mini);
}

.empty-content--description {
  color: var(--ui-secondary-disabled);
  letter-spacing: var(--letter-spacing-copy-wide);
  text-wrap: balance;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImVtcHR5X2NvbnRlbnQuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBRUE7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOzs7QUFFSjtFQUNFO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTtFQUNBOztBQUVBO0VBQ0U7OztBQUVKO0VBQ0U7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLmVtcHR5LWNvbnRlbnRcbiAgLS1pY29uLW1hcmdpbjogMFxuXG4gIGRpc3BsYXk6IGZsZXhcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtblxuICBnYXA6IHZhcigtLXNwYWNlci0zMilcbiAgdGV4dC1hbGlnbjogY2VudGVyXG5cbiAgJi4tc21hbGxcbiAgICAtLWljb24tbWFyZ2luOiAxMHB4XG4gICAgZ2FwOiB2YXIoLS1zcGFjZXItMTIpXG5cbi5lbXB0eS1jb250ZW50LS1pY29uXG4gIC0taWNvbi1tYXJnaW4tdG9wOiB2YXIoLS1pY29uLW1hcmdpbilcbiAgLS1pY29uLW1hcmdpbi1ib3R0b206IHZhcigtLWljb24tbWFyZ2luKVxuICAtLWljb24tY29sb3I6IHZhcigtLXdoaXRlKVxuXG4uZW1wdHktY29udGVudC0tdGl0bGVcbiAgY29sb3I6IHZhcigtLXVpLXByaW1hcnkpXG4gIG1hcmdpbjogMFxuXG4gIC5lbXB0eS1jb250ZW50Li1zbWFsbCAmXG4gICAgZm9udC1zaXplOiB2YXIoLS1mb250LXNpemUtY29weS1taW5pKVxuXG4uZW1wdHktY29udGVudC0tZGVzY3JpcHRpb25cbiAgY29sb3I6IHZhcigtLXVpLXNlY29uZGFyeS1kaXNhYmxlZClcbiAgbGV0dGVyLXNwYWNpbmc6IHZhcigtLWxldHRlci1zcGFjaW5nLWNvcHktd2lkZSlcbiAgdGV4dC13cmFwOiBiYWxhbmNlXG4iXX0= */