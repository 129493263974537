@import ../environment

.modal-header
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: auto
  grid-template-areas: "title" "subtitle"
  align-items: start

  margin-bottom: var(--spacer-32)

.modal-header--title
  grid-area: title

  margin: 0
  word-wrap: break-word

  color: var(--ui-primary)
  font-family: var(--headings-font-family)
  font-size: var(--font-size-h2)
  line-height: var(--modal-header-line-height)
  letter-spacing: var(--modal-header-letter-spacing)

.modal-header--subtitle
  grid-area: subtitle

  color: var(--info-text-color)
  letter-spacing: var(--letter-spacing-copy-medium)
  +copy-mini
