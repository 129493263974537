@import ../environment

.lca-tile-details
  align-items: center
  display: grid
  gap: 0
  grid-auto-columns: 1fr
  grid-auto-flow: column
  max-width: var(--lca-tile-details-max-width) // avoid too much spacing between tail details elements when supplier is missing

  +bold

.lca-tile-details--prefix
  font-size: var(--lca-tile-progress-font-size)

.lca-tile-details--icon
  display: inline-grid
  place-items: center

  &.-missing-supplier
    color: var(--ui-warning)

.lca-tile-details--suffix
  +copy
  width: 100%

  overflow: hidden
  text-overflow: ellipsis

  &.-missing-supplier
    color: var(--ui-warning)
    +copy-mini
    +copy-semibold
