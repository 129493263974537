@import ../../environment

.form-control
  +input-like
  +only-bottom-border
  +copy-semibold

  height: var(--input-height) !important

  &[disabled]
    // See also .ts-wrapper.form-select .ts-control
    background-color: var(--input-background-color)
    border-bottom: 0
    color: var(--interaction-secondary-disabled)
