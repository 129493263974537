// Forms with only a few simple fields should
// not be stretched to full width
$max-flat-form-width: 100%;

$container-max-widths: (
  sm: 540px,
  md: 600px,
  lg: 750px,
);

$screen-s-min: 390px;
$screen-m-min: 800px;
$screen-l-min: 1200px;
$screen-xl-min: 1440px;
$screen-xxl-min: 1920px;

$breakpoints: (
  xs: 0px,
  sm: $screen-s-min,
  md: $screen-m-min,
  lg: $screen-l-min,
  xl: $screen-xl-min,
  xxl: $screen-xxl-min,
);
