@import ../environment

.unauthenticated-layout
  background: url("/app/app/assets/application/components/../../images/blurry-login-background.jpg")
  background-size: cover

.unauthenticated-layout--logo
  --height: var(--unauthenticated-logo-height)
  position: fixed
  top: var(--unauthenticated-logo-top)
  left: var(--unauthenticated-logo-left)
  height: var(--height)
  width: calc(var(--height) * var(--aspect-ratio))

  z-index: 2001 // above Unpoly modals

  img
    width: 100%

.unauthenticated-layout--content
  min-height: 100vh
  min-width: 100vw
