.substitution-row-selection {
  display: flex;
  gap: var(--spacer);
  align-items: center;
  justify-content: space-between;
}

.substitution-row-selection--impact-chooser {
  flex: 1 1 auto;
  margin: 0 !important;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbInN1YnN0aXR1dGlvbl9yb3dfc2VsZWN0aW9uLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLnN1YnN0aXR1dGlvbi1yb3ctc2VsZWN0aW9uXG4gIGRpc3BsYXk6IGZsZXhcbiAgZ2FwOiB2YXIoLS1zcGFjZXIpXG4gIGFsaWduLWl0ZW1zOiBjZW50ZXJcbiAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuXG5cbi5zdWJzdGl0dXRpb24tcm93LXNlbGVjdGlvbi0taW1wYWN0LWNob29zZXJcbiAgZmxleDogMSAxIGF1dG9cbiAgbWFyZ2luOiAwIWltcG9ydGFudCAvLyBvdmVyd3JpdGUgZGVmYXVsdCBmb3JtIGdyb3VwIHNwYWNpbmcgdG8gYWxsb3cgZ29vZCB2ZXJ0aWNhbCBhbGlnbm1lbnRcbiJdfQ== */