@import ../environment

=when-horizontal-scroller
  .scroller.-horizontal &
    @content

=when-vertical-scroller
  .scroller.-vertical &
    @content

.scroller
  --fading-area-size: 30px
  --fading-area-offset-start: 0
  --fading-area-size-start: var(--fading-area-size)
  --fading-area-offset-end: 0
  --fading-area-size-end: var(--fading-area-size)
  --fading-border-area-size: var(--border-width-default)
  --fading-border-area-size-start: var(--fading-border-area-size)
  --fading-border-area-size-end: var(--fading-border-area-size)
  display: flex
  position: relative

  &.-scrollable
    cursor: grab
    user-select: none // Better UX in FF

  &.-dragging
    cursor: grabbing

  &.-gradients
    $gradient-color: $white-level-0
    $gradient: rgba($gradient-color, 0) 0, $gradient-color 100%

    // Fade out in the page margins
    &::before,
    &::after
      display: block
      position: absolute
      top: 0

      content: ""
      pointer-events: none

      border: 0 dashed var(--ui-highlight-primary)
      opacity: 0
      +transition-after-page-load((opacity var(--transition-duration-default), width var(--transition-duration-default)))

    &.-horizontal
      &::before
        height: 100%
        left: var(--fading-area-offset-start)
        top: 0
        width: var(--fading-area-size-start)

        background: linear-gradient(270deg, $gradient)
        border-left-width: var(--fading-border-area-size-start)

      &::after
        height: 100%
        right: var(--fading-area-offset-end)
        width: var(--fading-area-size-end)

        background: linear-gradient(90deg, $gradient)
        border-right-width: var(--fading-border-area-size-end)

      .scroller--button
        visibility: hidden
        top: var(--scroller-button-offset, calc(50% - #{var(--button-size)} / 2))

        &.-start
          left: var(--fading-area-offset-start)
          transform: translateX(calc(#{var(--button-size)} / 2))

        &.-end
          right: var(--fading-area-offset-end)
          transform: translateX(calc(#{var(--button-size)} / 2))

    &.-vertical
      &::before
        height: var(--fading-area-size-start)
        width: 100%

        background: linear-gradient(0deg, $gradient)
        border-top-width: var(--fading-border-area-size-start)

      &::after
        bottom: 0
        height: var(--fading-area-size-end)
        top: auto
        width: 100%

        background: linear-gradient(180deg, $gradient)
        border-bottom-width: var(--fading-border-area-size-end)

    &.-start-gradient-visible
      &::before
        opacity: 1

      .scroller--button.-start
        visibility: visible

    &.-end-gradient-visible
      &::after
        opacity: 1

      .scroller--button.-end
        visibility: visible

// This intermediate container is necessary to separate the content from the gradients. If we put the gradients and the
// scrollable content directly into the .scroller, the gradients would not be stationary but start scrolling
// as well.
.scroller--content-container
  --overflow-buffer-top: 0px
  --overflow-buffer-bottom: 0px

  +scrollbar-hidden
  width: 100%

  /* We cannot enable this behavior everywhere as it makes renders buttons etc. unclickable within the buffer range! */
  &.-with-overflow-buffer
    --overflow-buffer-top: 50px
    --overflow-buffer-bottom: 100px

  +when-horizontal-scroller
    overflow-x: scroll
    overflow-y: hidden

    // Hiding the y overflow means that some elements like tooltips tend to be clipped.
    // We add a small buffer below the element where children of the scroller are allowed to overflow.
    margin-top: calc(-1 * var(--overflow-buffer-top))
    padding-top: var(--overflow-buffer-top)
    margin-bottom: calc(-1 * var(--overflow-buffer-bottom))
    padding-bottom: var(--overflow-buffer-bottom)


  +when-vertical-scroller
    height: 100%

    overflow-x: hidden
    overflow-y: scroll

.scroller--content
  flex-wrap: nowrap
  min-width: 100%
  width: fit-content
