@keyframes tilt-shaking
  $max-rotation: 25deg
  0%
    transform: rotate(0)
  25%
    transform: rotate($max-rotation)
  50%
    transform: rotate(0)
  75%
    transform: rotate(-$max-rotation)
  100%
    transform: rotate(0)
