@import ../environment

.status-infos
  display: flex
  flex-direction: column
  gap: var(--spacer-16)
  margin-block: var(--spacer-24)

.status-info
  padding: var(--spacer-12) var(--spacer-20)
  display: flex
  justify-content: center
  gap: var(--spacer-8)
  align-items: center
  color: var(--white)
  +copy
  +copy-semibold

  &.-info
    background-color: var(--ui-info)

  &.-success
    background-color: var(--ui-success)

  &.-warning
    background-color: var(--ui-warning)

  &.-error
    background-color: var(--ui-error)

  a
    text-decoration: underline
