@import ../environment

textarea
  &.form-control
    min-height: calc(var(--input-height) * 3)

    letter-spacing: var(--letter-spacing-copy-wide)

  &.-large
    min-height: calc(var(--input-height) * 4.5)

  &.-unmodified-value
    color: var(--interaction-primary-active)
