@import ../../environment

.table
  margin-bottom: 0

  thead
    border-bottom: var(--border-width-default) solid var(--background-level-1)

  thead, tfoot
    --bs-table-color: var(--ui-secondary)
    --table-cell-height: var(--table-header-height)

  tbody
    --table-cell-height: var(--table-row-height)

    tr
      &:hover,
      &.-hovered
        --bs-table-bg-state: var(--bs-table-hover-bg)

  // This is is bootstrap's rule targeting all table cell (td) elements. We have to use it as well to overwrite it.
  &:not(.empty-table) > :not(caption) > * > *
    height: var(--table-cell-height)
    padding: 0 var(--table-cell-padding-inline)
    vertical-align: middle
    width: var(--table-cell-target-width, auto)

    &:not(.-narrow)
      min-width: var(--table-cell-min-width)

    & > span:not(.allow-many-lines)
      +line-clamp(2)

    @for $i from 1 through 10
      &.-allow-#{$i}-lines
        & > span
          +line-clamp(#{$i})

    &.non-mobile-only
      +on-mobile-screens
        display: none

      +on-non-mobile-screens
        display: table-cell

    &.mobile-only
      +on-mobile-screens
        display: table-cell

      +on-non-mobile-screens
        display: none
