// Google Fonts does not offer downloading WOFF2 files directly, though they are publicly available.
// We fetched them using https://gwfh.mranftl.com/fonts/open-sans?subsets=latin
// For our CI font definitions see https://www.figma.com/file/SkJOkei9mhIfCABwk4n8ds/Design-System?type=design&node-id=1-9199&t=vdWuhshri4Dc08ch-0


@font-face
  font-family: 'Open Sans'
  font-display: swap
  font-style: normal
  font-weight: 400
  src: local(''), url('/app/app/assets/fonts/./open_sans/open-sans-v35-latin-regular.woff2') format('woff2')

@font-face
  font-family: 'Open Sans'
  font-display: swap
  font-style: italic
  font-weight: 400
  src: local(''), url('/app/app/assets/fonts/./open_sans/open-sans-v35-latin-italic.woff2') format('woff2')

@font-face
  font-family: 'Open Sans'
  font-display: swap
  font-style: normal
  font-weight: 600
  src: local(''), url('/app/app/assets/fonts/./open_sans/open-sans-v35-latin-600.woff2') format('woff2')

@font-face
  font-family: 'Open Sans'
  font-display: swap
  font-style: italic
  font-weight: 600
  src: local(''), url('/app/app/assets/fonts/./open_sans/open-sans-v35-latin-600italic.woff2') format('woff2')
