@import ../environment

.sidebar-toggle-button
  --button-size: 25px

  display: grid
  place-items: center

  cursor: pointer

  +on-non-mobile-screens
    width: var(--button-size)
    height: var(--button-size)

    border-radius: var(--max-border-radius)
    transform: translateX(calc(#{var(--button-size)} / 2))

    background-color: var(--ui-highlight-primary)

    +active-hover
      background-color: var(--ui-highlight-primary-hover)

.sidebar-toggle-button--icon
  +when-sidebar-collapsed
    +icon(sidebar-open, $size: 10)
    transform: translateX(1px)

    +on-mobile-screens
      +icon(sidebar-open-mobile, $size: 40)

  +when-sidebar-expanded
    +icon(sidebar-close, $size: 10)

    +on-mobile-screens
      +icon(sidebar-close-mobile, $size: 40)
