@import ../environment
.modal-mini-link
  font-size: var(--font-size-copy-small)
  color: var(--text-color)

.modal-mini-link--divider
  display: inline-block
  margin-inline: var(--spacer-12)
  width: var(--border-width-default)
  height: 12px
  align-self: center

  background-color: var(--text-color)
