@import ../../environment

.form-label
  color: var(--ui-secondary)

  .form-group.-for-textarea &
    background-color: var(--input-background-color)

  &.-sources-and-notes
    &::before
      content: ''
      +icon(stage-edit-source-and-notes, $size: 20)
      margin-right: var(--spacer-12)
      --size: var(--small-icon-size)

      color: var(--white)

      .form-group.-with-value &,
      .form-group.-focused &
        display: none

  span.required-mark
    --bs-danger-rgb: var(--ui-secondary)
