@import ../environment
@import ../config/variables/icons

\:root
  --mini-icon-size: 10px
  --small-icon-size: 20px /* default for the IconHelper */
  --medium-icon-size: 40px
  --large-icon-size: 60px

.icon
  +icon-without-url

  @each $name, $_filename in $medium-icons
    &.-#{$name}
      +medium-icon-url($name)

  &.-spacer
    margin-inline-end: var(--spacer-12)

  &.-x10
    --size: var(--mini-icon-size)

    @each $name, $_filename in $mini-icons
      &.-#{$name}
        +mini-icon-url($name)

  &.-x20
    --size: var(--small-icon-size)

    @each $name, $_filename in $small-icons
      &.-#{$name}
        +small-icon-url($name)

  &.-x60
    --size: var(--large-icon-size)

    @each $name, $_filename in $large-icons
      &.-#{$name}
        +large-icon-url($name)

  &.-active
    color: var(--interaction-primary-active)

  &.-disabled
    color: var(--interaction-secondary-disabled)
