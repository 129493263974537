@import ../environment

.product-summary
  margin: var(--spacer-64) 0 var(--spacer-40) 0

  display: grid
  column-gap: var(--spacer-40)
  row-gap: var(--spacer-12)
  grid-template-columns: auto auto minmax(auto, 1fr)
  grid-template-rows: auto auto
  grid-template-areas: "data-1 data-2 costs" "data-3 data-4 costs"

  +media-breakpoint-down(lg)
    row-gap: var(--spacer-20)
    grid-template-columns: auto
    grid-template-rows: auto auto auto auto minmax(0, 1fr)
    grid-template-areas: "data-1" "data-2" "data-3" "data-4" "costs"

.product-summary--entry
  +copy
  font-weight: 600
  letter-spacing: var(--letter-spacing-copy-wide)

  &.-data-1
    grid-area: data-1

  &.-data-2
    grid-area: data-2

  &.-data-3
    grid-area: data-3

  &.-data-4
    grid-area: data-4

  &.-costs
    grid-area: costs

    +media-breakpoint-up(lg)
      display: inline-block
      margin-left: auto

  &.-muted
    color: var(--interaction-secondary-disabled)

.product-summary--entry-link
  +text-link
  display: grid
  grid-template-columns: auto minmax(auto, 1fr)
