@import ./forms

$enable-shadows: true
$enable-gradients: true
$enable-validation-icons: false
$enable-rounded: false
$enable-rfs: false // see typography sass files
$min-contrast-ratio: 2

// sadly we cannot use var(--ui-success) etc. here
// as bootstrap needs RGB values to apply operations
// like shift-color.

////
// Basic Colors
$black: $brand-black
$body-bg: $background-level-0
$dark: $brand-black

$primary: $ui-highlight-primary
$secondary: $ui-highlight-secondary

$success: $ui-success
$warning: $ui-warning
$danger: $ui-error

$body-color: $ui-primary // must be a SASS variable
$text-muted: var(--interaction-primary-disabled)
$link-color: $interaction-primary-default
$link-hover-color: $interaction-primary-hover
$border-color: var(--ui-primary)
$headings-color: var(--ui-primary)

////
// Tables
$table-color: var(--ui-primary)
$table-bg: var(--background-level-0)
$table-hover-bg: var(--white-level-1)

$table-cell-padding-x: var(--spacer-20)
$table-cell-padding-y: 0
$table-border-width: 0px

////
// Link navigation
$nav-link-color: var(--interaction-primary-default)
$nav-tabs-link-active-color: var(--interaction-primary-active)
$nav-tabs-link-disabled-color: var(--interaction-primary-disabled)

////
// Navbar
$navbar-dark-active-color: var(--interaction-primary-active)

////
// Inputs
$input-box-shadow: null
$input-focus-border-color: var(--input-border-color-focus)
$input-disabled-bg: var(--interaction-secondary-disabled)
$input-transition: none
$input-bg: var(--input-background-color)
$input-font-size: var(--input-font-size)
$input-group-addon-bg: transparent
$input-border-width: 0 // we have to add a border-bottom manually to all relevant elements
$input-border-color: var(--input-border-color)
$input-height-border: var(--input-border-height)
$input-padding-x: $input-horizontal-padding
$input-padding-y: $input-padding-top
$input-height: var(--input-height)

////
// Buttons
$btn-font-family: var(--button-font-family)
$btn-font-size: var(--button-font-size)
$input-btn-padding-x: $button-horizontal-padding
$input-btn-padding-y: $button-vertical-padding
$btn-border-width: 0
$btn-disabled-opacity: 1 // We use fixed disabled colors for disabled buttons
$btn-font-weight: var(--button-font-weight)
$btn-line-height: var(--button-line-height)
$btn-box-shadow: unset

////
// Forms
$form-group-spacer: var(--spacer-16)
$form-select-border-width: $input-border-width
$form-check-input-border: 1px solid var(--ui-primary)


/////
// Tooltips
$tooltip-color: $ui-primary

@import bootstrap/scss/functions
@import bootstrap/scss/variables

@import bootstrap_variables_loader
