@import '../environment'

.lca-checks-form-box
  background-color: var(--background-level-2)
  padding: var(--spacer-20)

.lca-checks-form-box--title
  +copy
  font-weight: 600

.lca-checks-form-box--content
  margin-top: var(--spacer-12)
