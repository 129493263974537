// Keep in sync with root/_spacers.sass and the Design test page
$spacer-sizes: 2, 4, 8, 12, 16, 20, 24, 32, 40, 48, 64, 80, 128
@each $size in $spacer-sizes
  .spacer-top-#{$size}
    margin-top: var(--spacer-#{$size})
  .spacer-right-#{$size}
    margin-right: var(--spacer-#{$size})
  .spacer-bottom-#{$size}
    margin-bottom: var(--spacer-#{$size})
  .spacer-left-#{$size}
    margin-left: var(--spacer-#{$size})
