[line-clamp] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

[line-clamp="2"] {
  -webkit-line-clamp: 2;
}

[line-clamp="3"] {
  -webkit-line-clamp: 3;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImxpbmVfY2xhbXAuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFXQTtFQUNFO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTs7O0FBRUY7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi8vIEFzc2lnbiB0aGUgYFtsaW5lLWNsYW1wXWAgYXR0cmlidXRlIHRvIGFueSBlbGVtZW50IHRvIG1ha2UgaXRzIHRleHQgdHJ1bmNhdGUgd2l0aCBhbiBlbGxpcHNpczpcbi8vXG4vLyAgICAgPGRpdiBsaW5lLWNsYW1wPVwiMlwiPlxuLy8gICAgICAgIFN1cGVyIGxvbmcgdGV4dCB3aWxsIGJlIGNsYW1wZWQgYWZ0ZXIgMiBsaW5lcy5cbi8vICAgICA8L2Rpdj5cbi8vXG4vLyBUaGUgd2VpcmQgQ1NTIHByb3BlcnRpZXMgYXJlIGFjdHVhbGx5IHN1cHBvcnRlZCBieSBtYW55IGJyb3dzZXJzLCBkZXNwaXRlIGJlaW5nIHByZWZpeGVkIHdpdGggXCItd2Via2l0XCIuXG4vLyBTZWUgW0NTUy10cmlja3MgYXJ0aWNsZV0oaHR0cHM6Ly9jc3MtdHJpY2tzLmNvbS9saW5lLWNsYW1waW4vKS5cbi8vXG4vLyBUaGVyZSBpcyBhbHNvIGBGb3JtYXRIZWxwZXIjbGluZV9jbGFtcGAgdG8gaGVscCBhcHBseWluZyB0aGlzIGF0dHJpYnV0ZSB0byBhIGJsb2NrIG9mIEhUTUwuXG4vL1xuW2xpbmUtY2xhbXBdXG4gIGRpc3BsYXk6IC13ZWJraXQtYm94XG4gIC13ZWJraXQtYm94LW9yaWVudDogdmVydGljYWxcbiAgb3ZlcmZsb3c6IGhpZGRlblxuXG5bbGluZS1jbGFtcD0nMiddXG4gIC13ZWJraXQtbGluZS1jbGFtcDogMlxuXG5bbGluZS1jbGFtcD0nMyddXG4gIC13ZWJraXQtbGluZS1jbGFtcDogM1xuIl19 */