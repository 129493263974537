@import '../environment'

.lca-configuration-legend
  --dot-size: 15px

.lca-configuration-legend--label
  display: flex
  align-items: center
  gap: var(--spacer-8)

  &::before
    content: ''
    display: inline-flex
    width: var(--dot-size)
    height: var(--dot-size)
    border-radius: var(--max-border-radius)

  &.-primary::before
    background-color: var(--stage-configuration-primary)

  &.-secondary::before
    background-color: var(--stage-configuration-secondary)

  &.-excluded::before
    background-color: var(--stage-configuration-excluded)
