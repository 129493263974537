@import ../environment

.requested-changes
  +copy
  outline: 1px solid var(--ui-warning)

  &--header
    +copy-semibold
    display: flex
    justify-content: center
    gap: var(--spacer-8)
    padding: var(--spacer-12) var(--spacer-20)
    color: var(--ui-warning)
    background-color: var(--ui-info)

  &--content
    padding: var(--spacer-12) var(--spacer-20)