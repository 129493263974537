@import ../environment

.scroller-button
  --button-size: 25px
  display: grid
  height: var(--button-size)
  place-items: center
  position: absolute
  width: var(--button-size)
  z-index: 5 // Overtops input group addons which have a high z-index

  background-color: var(--ui-highlight-primary)
  border-radius: var(--max-border-radius)
  cursor: pointer

  +active-hover
    background-color: var(--ui-highlight-primary-hover)

.scroller-button--icon
  .-end &
    +icon(sidebar-open, $size: 10)
    transform: translateX(1px)
