.form--row {
  margin-top: var(--spacer-24);
}
.form--row.-margin-top-0 {
  margin-top: 0;
}

.form--section {
  margin-top: var(--spacer-32);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImZvcm0uc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFOztBQUVBO0VBQ0U7OztBQUVKO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuZm9ybS0tcm93XG4gIG1hcmdpbi10b3A6IHZhcigtLXNwYWNlci0yNClcblxuICAmLi1tYXJnaW4tdG9wLTBcbiAgICBtYXJnaW4tdG9wOiAwXG5cbi5mb3JtLS1zZWN0aW9uXG4gIG1hcmdpbi10b3A6IHZhcigtLXNwYWNlci0zMilcbiJdfQ== */