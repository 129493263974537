@import ../environment

.form-group:not(.disabled)[field-with-dropdown]
  display: grid
  grid-template-areas: "input icon" "invalid-feedback invalid-feedback"
  grid-template-columns: 1fr auto
  align-items: center
  --icon-offset: calc((var(--input-select-icon-margin-right) + var(--small-icon-size)) * -1)

  &:after
    content: ''
    +icon(arrow-down, $size: 20)
    margin-left: var(--icon-offset)
    --size: var(--small-icon-size)
    pointer-events: none
    grid-area: icon

  &.-dropdown-open:after
    +small-icon-url(arrow-up)
