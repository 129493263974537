// Bootstrap's .toast did not fit our case well enough, so we re-implemented something similar to .toast
// This component is shared across environments, so we did not specify shadows or rounded corners. Please apply utility classes like `.shadow` or `.rounded` as needed.

@import ../config/mixins

.flash-message
  --line-height: var(--bs-body-line-height)
  pointer-events: auto
  position: relative

  display: grid
  grid-template-columns: min-content auto min-content min-content
  grid-template-rows: auto
  grid-template-areas: "icon text close"
  gap: var(--flash-message-gap)
  align-items: center

  +h3
  overflow: hidden

  box-shadow: var(--box-shadow)
  max-width: min(var(--flash-message-max-width), 100%)
  min-width: min(calc(var(--container-max-sm) - var(--spacer-40)), 100%) // Don't break out of small modals
  padding: var(--spacer-12) var(--spacer-20)
  margin-bottom: var(--spacer-12)

  background: var(--flash-background)
  color: var(--flash-color)

  &.-info // default
    --flash-background: var(--white)
    --flash-color: var(--black)
  &.-success
    --flash-background: var(--ui-success)
    --flash-color: var(--white)
  &.-warning
    --flash-background: var(--ui-warning)
    --flash-color: var(--white)
  &.-danger
    --flash-background: var(--ui-error)
    --flash-color: var(--white)

.flash-message--icon
  // Wiggle twice during the five second lifetime of a flash message
  // See [flash-message] timeoutDelay
  +wiggle-animation
  grid-area: icon
  font-size: calc(#{var(--line-height)} * 1rem)
  // our font-size is set to the line height of .flash-message--text (effectively 1.5rem),
  // so we need to set our line-height 1 to align icon and text.
  line-height: 1

.flash-message--text
  grid-area: text
  overflow: hidden
  overflow-wrap: break-word

.flash-message--close
  grid-area: close
  margin: -.25rem -.25rem -.25rem var(--spacer-20)
  padding: .25rem

.flash-message--timer-indicator
  position: absolute
  top: 0
  left: 0
  height: .25rem
  width: 0 // updated from flash_message.js
  background-color: var(--flash-background)
