@import ../environment

.breadcrumbs
  --breadcrumbs-gap: 4px

  display: flex
  gap: var(--breadcrumbs-gap)
  margin-bottom: var(--spacer-12)
  align-items: baseline

.breadcrumbs--layer
  letter-spacing: var(--letter-spacing-copy-medium)
  +copy-mini

  &:not(.-active)
    color: var(--interaction-secondary-disabled)

  &:hover
    color: var(--link-hover-color)

  &:focus
    color: var(--interaction-secondary-disabled)
