@use "sass:math"
@use "sass:map"
@import ../variables/breakpoints

\:root
  $breakpoint-names: map-keys($breakpoints)
  @for $i from 1 through length($breakpoint-names)
    $name: nth($breakpoint-names, $i)
    $container-width: map-get($container-max-widths, $name)

    @if $container-width == 100%
      $next-name: nth($breakpoint-names, $i + 1)
      $next-width: map-get($breakpoints, $next-name)
      --container-max-#{$name}: calc(#{$next-width} - 2 * var(--container-padding))
    @else if $container-width
      --container-max-#{$name}: #{map-get($container-max-widths, $name)}

  --max-flat-form-width: #{$max-flat-form-width}
