.stage-activity {
  position: relative;
  padding: 0 0 30px 60px;
}
.stage-activity:before {
  content: "";
  position: absolute;
  left: 25px;
  top: 0;
  bottom: 0;
  width: 1px;
  background: var(--ui-highlight-primary-60);
}
.stage-activity:last-of-type:before {
  display: none;
}

.stage-activity--anchor {
  position: relative;
  top: -20px;
}

.stage-activity--icon {
  display: block;
  position: relative;
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  padding: 12px 0;
  float: left;
  margin-left: -60px;
  background: var(--ui-highlight-primary);
}

.stage-activity--content {
  padding: 15px;
  border-radius: 10px;
  background: var(--background-level-1);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbInN0YWdlX2FjdGl2aXRpZXMuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHQTtFQUNFOzs7QUFFTjtFQUNFO0VBQ0E7OztBQUVGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuc3RhZ2UtYWN0aXZpdHlcbiAgcG9zaXRpb246IHJlbGF0aXZlXG4gIHBhZGRpbmc6IDAgMCAzMHB4IDYwcHhcblxuICAmOmJlZm9yZVxuICAgIGNvbnRlbnQ6IFwiXCJcbiAgICBwb3NpdGlvbjogYWJzb2x1dGVcbiAgICBsZWZ0OiAyNXB4XG4gICAgdG9wOiAwXG4gICAgYm90dG9tOiAwXG4gICAgd2lkdGg6IDFweFxuICAgIGJhY2tncm91bmQ6IHZhcigtLXVpLWhpZ2hsaWdodC1wcmltYXJ5LTYwKVxuXG4gICY6bGFzdC1vZi10eXBlXG4gICAgJjpiZWZvcmVcbiAgICAgIGRpc3BsYXk6IG5vbmVcblxuLnN0YWdlLWFjdGl2aXR5LS1hbmNob3JcbiAgcG9zaXRpb246IHJlbGF0aXZlXG4gIHRvcDogLTIwcHhcblxuLnN0YWdlLWFjdGl2aXR5LS1pY29uXG4gIGRpc3BsYXk6IGJsb2NrXG4gIHBvc2l0aW9uOiByZWxhdGl2ZVxuICB6LWluZGV4OiAxXG4gIHdpZHRoOiA1MHB4XG4gIGhlaWdodDogNTBweFxuICBib3JkZXItcmFkaXVzOiA1MCVcbiAgdGV4dC1hbGlnbjogY2VudGVyXG4gIHBhZGRpbmc6IDEycHggMFxuICBmbG9hdDogbGVmdFxuICBtYXJnaW4tbGVmdDogLTYwcHhcbiAgYmFja2dyb3VuZDogdmFyKC0tdWktaGlnaGxpZ2h0LXByaW1hcnkpXG5cblxuLnN0YWdlLWFjdGl2aXR5LS1jb250ZW50XG4gIHBhZGRpbmc6IDE1cHhcbiAgYm9yZGVyLXJhZGl1czogMTBweFxuICBiYWNrZ3JvdW5kOiB2YXIoLS1iYWNrZ3JvdW5kLWxldmVsLTEpXG4iXX0= */