@import ../environment

.dashboard-segment
  display: grid
  grid-template-areas: "title" "body"
  grid-template-rows: auto 1fr
  grid-template-columns: 100%

  &.-expanded
    flex: 1

.dashboard-segment--title
  grid-area: title
  padding-block: var(--spacer-8)
  color: var(--info-text-color)

.dashboard-segment--body
  grid-area: body
