.fancytree-container
  background: var(--input-background-color)
  margin: var(--spacer-40) 0
  width: 100%

  thead
    background: var(--background-level-1)

    th
      color: var(--white)
      padding: var(--spacer-20)
      font-size: var(--font-size-h4)
      text-align: left
      vertical-align: middle

  .fancytree-node
    display: flex !important
    align-items: center

    .fancytree-title
      color: var(--white)
      font-size: var(--input-font-size)
      line-height: 1.5

  .fancytree-active, .fancytree-focused
    background-color: var(--ui-highlight-primary-hover) !important

