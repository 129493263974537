@import ../config/mixins

// The intro animation will first play full screen before fading to the top left edge
//  where it tries to resemble the .unauthenticated-layout--logo
.intro-animation
  position: fixed
  width: 100%
  height: 100%
  left: 0
  top: 0
  margin-left: 0
  margin-top: 0
  transition: all 1s ease-in

  &.-finished
    transform-origin: top left
    margin-top: var(--unauthenticated-logo-top)
    margin-left: var(--unauthenticated-logo-left)
