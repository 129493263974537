.reference-impact-chooser
  min-width: var(--reference-impact-input-min-width)

.reference-impact-chooser--visible-input
  &:not([disabled])
    cursor: pointer

.reference-impact-chooser--reset
  padding-left: 0
  display: none
  align-self: end
  color: var(--interaction-primary-active)

  .-with-value &
    display: block
