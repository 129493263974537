@import ../environment

.help-overlay
  display: grid
  grid-template-areas: "icon" "." "title" "." "text" "." "help-center-hint"
  grid-template-rows: auto var(--spacer-32) auto var(--spacer-32) auto 1fr auto
  height: 100%

.help-overlay--title
  grid-area: title

  font-size: var(--font-size-h2)
  line-height: 36px

.help-overlay--icon
  grid-area: icon
  justify-self: center

  +wiggle-animation

.help-overlay--text
  grid-area: text

.help-overlay--hint
  grid-area: help-center-hint

  a[href]
    +bold
