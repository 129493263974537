@import ../environment

.sidebar-mini-links
  display: flex
  justify-content: space-evenly

.sidebar-mini-link
  font-size: var(--font-size-copy-small)

.sidebar-mini-link--divider
  display: inline-block
  margin-inline: var(--spacer-8)
  width: var(--border-width-default)

  background-color: var(--white)
