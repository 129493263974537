@import ../../environment

.form-section-hint
  margin-bottom: var(--spacer-16)

  +bold
  letter-spacing: var(--letter-spacing-copy-medium)
  color: var(--ui-primary)
  font-size: var(--font-size-copy-mini)
  line-height: var(--line-height-copy-mini)
