@import ../environment

.subform-in-overlay
  margin-top: var(--spacer-24)
  margin-bottom: var(--spacer-48)

  +on-mobile-screens
    margin-bottom: var(--spacer-24)

  &.-margin-bottom-0
    margin-bottom: 0

  > .subform-in-overlay--fields
    // Only show the fields while they are extracted into the overlay.
    display: none

// only show warning if root has modifier
.subform-in-overlay--warning
  display: none
  margin-top: var(--spacer-8)

  .-has-warning &
      display: block
