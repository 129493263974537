@import ../../environment

=when-nav-sub-tabs
  .nav-tabs.-sub-tabs &
    @content

=when-nav-link-active
  .nav-link.active &
    @content

=when-active-nav-link-dirty
  .nav-link.active.-dirty &
    @content

.nav-link
  +when-nav-sub-tabs
    --bs-nav-link-padding-x: 0
    --bs-nav-link-padding-y: 0
    --icon-container-height: 80px
    --stroke-container-height: 3px
    --stroke-color: var(--ui-primary)

    display: inline-grid
    flex-grow: 1
    grid-template-areas: "icon" "stroke" "." "title"
    grid-template-columns: 1fr
    grid-template-rows: var(--icon-container-height) var(--stroke-container-height) var(--spacer-16) auto
    justify-content: center
    width: var(--nav-link-width, 100%)

    transition: color var(--transition-duration-default)

    &:active,
    &:hover
      --bs-nav-link-hover-color: var(--link-hover-color)
      --stroke-color: var(--link-hover-color)

    +on-mobile-screens
      --icon-container-height: 60px
      grid-template-areas: "icon" "stroke"
      grid-template-rows: var(--icon-container-height) var(--stroke-container-height)

.nav-link--icon
  +when-nav-sub-tabs
    grid-area: icon
    place-self: center

    &.-lca-stage-unsaved
      --icon-color: var(--white)

    &.-lca-stage-draft
      --icon-color: var(--white)

    &.-lca-stage-in-review
      --icon-color: var(--ui-warning)

    &.-lca-stage-changes-requested
      --icon-color: var(--ui-error)

    &.-lca-stage-approved,
    &.-lca-stage-completed
      --icon-color: var(--ui-success)

    &.-for-mobile
      display: none

    +on-mobile-screens
      &.-for-desktop
        display: none

      &.-for-mobile
        display: initial

  +when-nav-link-active
    +when-nav-sub-tabs
      &.-for-desktop
        --icon-color: var(--interaction-primary-hover)

      &.-for-mobile
        --icon-color: var(--interaction-primary-hover)
        transform: scale(1.5)

  +when-active-nav-link-dirty
    +when-nav-sub-tabs
      &.-for-desktop
        +medium-icon-url(lca-stage-active)

      &.-for-mobile
        +small-icon-url(lca-stage-active)

.nav-link--stroke
  +when-nav-sub-tabs
    align-self: flex-end
    grid-area: stroke
    height: var(--border-width-default)

    background-color: var(--stroke-color)

  +when-nav-link-active
    +when-nav-sub-tabs
      height: var(--border-width-active)
      --stroke-color: var(--interaction-primary-hover)

.nav-link--title
  +when-nav-sub-tabs
    grid-area: title
    justify-self: center
    max-width: calc(100% - 2 * var(--spacer-8))

    overflow-x: hidden
    white-space: nowrap

    +copy-mini
    text-overflow: ellipsis

    +on-mobile-screens
      display: none

  +when-nav-link-active
    +when-nav-sub-tabs
      +bold
