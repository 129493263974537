@import ../environment

.action-table
  margin-bottom: var(--table-margin-bottom)

  .scroller
    --fading-area-size: 0
    --fading-border-area-size-start: 0

    +on-non-mobile-screens
      --fading-area-offset-end: var(--action-width)

  .scroller--button
    --scroller-button-offset: calc(#{var(--table-header-height)} / 2 - #{var(--button-size)} / 2)

  @for $i from 2 through 4
    &.-with-action-count-#{$i}
      .action-table--actions
        --table-cell-target-width: calc(#{$i} * var(--action-width))

      +on-non-mobile-screens
        .scroller
          --fading-area-offset-end: calc(#{$i} * var(--action-width))

.action-table--actions
  --table-cell-target-width: var(--action-width)
  --table-cell-min-width: var(--table-cell-target-width)
  --table-cell-padding-inline: 0
  cursor: default

  +on-non-mobile-screens
    position: sticky
    right: 0
