@import ../environment

.lca-tile
  --header-height: 44px
  --lca-tile-status-label: 25px
  --disabled-opacity: 0.3

  display: grid
  grid-template-areas: ". . ." ". header ." ". . ." "line-start . line-end" ". . ." ". details ." ". . ." ". actions ." ". . ." "status-label-start . status-label-end" "status-label-start . status-label-end"
  grid-template-columns: var(--spacer-24) 1fr var(--spacer-24)
  grid-template-rows: var(--spacer-24) var(--header-height) var(--spacer-24) var(--border-width-default) var(--spacer-24) auto var(--spacer-16) auto var(--spacer-24) var(--lca-tile-status-label)
  justify-items: center

  background-color: var(--ui-highlight-primary-disabled)
  border-radius: var(--tile-border-radius)
  box-shadow: 0 4px var( --lca-tile-blur-radius) rgba(0, 0, 0, var(--transparency-level-2))

  &.-current
    --gradient-color-highlight: var(--ui-highlight-primary)
    --gradient-color-dark: var(--ui-highlight-primary-disabled)
    --lca-tile-current-radial-gradient: radial-gradient(circle at center, var(--gradient-color-highlight), var(--gradient-color-dark))

    background: var(--lca-tile-current-radial-gradient)

  &.-disabled,
  &.-analyze-data:has(.disabled)
    opacity: var(--disabled-opacity)

  .lca.-archived &
    opacity: var(--disabled-opacity)

    &:hover
      opacity: unset

  +on-mobile-screens
    grid-template-columns: var(--spacer-24) 1fr var(--spacer-24)

.lca-tile--header
  align-items: center
  display: flex
  flex-direction: column
  grid-area: header
  max-width: 100%

  padding-bottom: var(--inner-padding)

  color: var(--interaction-primary-active)

.lca-tile--title
  --line-height-lca-tile-title: 21px

  +copy
  +uppercase
  font-family: var(--headings-font-family-bolder)
  letter-spacing: var(--letter-spacing-copy-xxl)
  line-height: var(--line-height-lca-tile-title)
  text-align: center
  white-space: normal

.lca-tile--subtitle
  +copy
  +copy-semibold

.lca-tile--divider
  grid-column: line-start / line-end
  grid-row: line-start / line-end
  height: 100%
  width: 100%

  background-color: var(--ui-highlight-primary)

.lca-tile--details
  grid-area: details

  text-align: center
  white-space: normal

.lca-tile--actions
  grid-area: actions

.lca-tile--status-label
  --lca-tile-label-radial-gradient: radial-gradient(circle at center, var(--lca-tile-label-gradient-color-highlight) 0, var(--lca-tile-label-gradient-color-dark) 100%)

  +copy-mini
  +copy-semibold
  line-height: var(--line-height-copy) // needed for vertical centering

  grid-column: status-label-start / status-label-end
  grid-row: status-label-start / status-label-end
  height: 100%
  width: 100%

  text-align: center
  white-space: normal

  background: var(--lca-tile-label-radial-gradient)
  border-radius: 0 0 var(--tile-border-radius) var(--tile-border-radius)
  color: var(--white)

  &.-positive
    --lca-tile-label-gradient-color-highlight: var(--gradient-color-green-highlight)
    --lca-tile-label-gradient-color-dark: var(--gradient-color-green-dark)

  &.-progressing
    --lca-tile-label-gradient-color-highlight: var(--gradient-color-violet-highlight)
    --lca-tile-label-gradient-color-dark: var(--gradient-color-violet-dark)

  &.-neutral
    --lca-tile-label-gradient-color-highlight: var(--gradient-color-grey-highlight)
    --lca-tile-label-gradient-color-dark: var(--gradient-color-grey-dark)
