=within-unit-selection-field
  .units-field--selection &
    @content

=input-like
  min-height: var(--input-height)
  min-width: var(--input-min-width)
  border-radius: var(--border-radius)
  box-shadow: $input-box-shadow !important
  padding: var(--input-padding-top) var(--input-horizontal-padding) var(--input-padding-bottom)
  font-family: $input-font-family
  @include font-size($input-font-size)
  font-weight: $input-font-weight
  line-height: $input-line-height
  caret-color: var(--interaction-primary-active)
  color: $input-color
  background-color: var(--input-background-color)

  +within-unit-selection-field
    min-width: 0

=within-deleted-row
  &[disabled], .dynamic-row.-marked-for-deletion &
    @content

// Our Design System only requires a border-bottom for most form elements
// To archive this, we set $input-border-width to zero and manually add a
// border-bottom to all affected elements
=only-bottom-border
  border: 0
  border-bottom: var(--input-border-height) solid var(--input-border-color)
  // Focus effects should rely on the border color instead of the box shadow
  box-shadow: none!important
  transition: border-bottom var(--transition-duration-default)

  // propagate focus border color to all siblings
  // if one input was focused
  .input-group.-focused &,
  .form-group.-focused &,
  .form-group.-focus-outline &
    border-bottom-color: var(--input-border-color-focus)

  +within-deleted-row
    border-bottom: 0
