@import ../environment

.lca-header
  display: flex
  justify-content: space-between
  align-items: center

  +on-mobile-screens
    flex-direction: column

.lca-header--lca-version
  display: flex
  align-items: baseline
  gap: var(--spacer-8)

.lca-header--name
  +bold

.lca-header--actions
  align-items: center
  display: flex
  flex-shrink: 0
  gap: var(--spacer-12)
  padding-block: var(--spacer-12)
