$brand-air: #6BB275 // success, positive response
$brand-basalt: #2F2F2F // background
$brand-concrete: #7A7A7A // disabled buttons
$brand-earth: #FF8E42 // warnings
$brand-fire: #FF6454 // errors, negative notifications
$brand-limestone: #F1F1F1 // font on background
$brand-onyx: #121219 // sidebar, modals. "Brand black", root background
$brand-black: $brand-onyx
$brand-slate: #454545 // input strokes
$brand-stone: #BEBEBE // hover buttons, disabled text
$brand-tourmaline: #1C1C21 // sidebar; background
$brand-transparency-1: 0.06 // 6% white -> #1F2129 on onyx; background inputs and dropdowns
$brand-transparency-2: 0.15 // 15% white -> #35363D on onyx; overlay modals
$brand-transparency-3: 0.70 // 70% white -> #B8B8BA on onyx
$brand-water-bright: #9F94FF // fonts on dark background; important: only  highlight single words, no quantity text allowed; icons active state
$brand-water-dark: #252154 // water -> disabled
$brand-water-hover: #3D3592
$brand-water: #4A41AD // primary buttons (CTAs), highlight active state

// TODO; These colors have not yet been defined in the Design System and will be refactored in #186897402
$brand-gradient-neutral: #A0A0A0
$brand-gradient-progressing-dark: #8A7DFF
$brand-gradient-positive-dark: #4E8556
$brand-gradient-positive-highlight: #73B97D
