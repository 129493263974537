@import '../environment'

.lca-report-form
  display: flex
  gap: var(--spacer-32)
  align-items: center
  background-color: var(--background-level-2)
  padding: var(--spacer-20)
  text-align: center

  +on-mobile-screens
    flex-direction: column
    gap: var(--spacer-16)

  &--status
    font-style: italic
    display: flex
    align-items: center
    gap: var(--spacer-8)

  &--report-download
    +text-link

  &--progress-icon
    color: var(--ui-warning)

  &--success-icon
    color: var(--ui-success)

  &--failed-icon
    color: var(--ui-error)
