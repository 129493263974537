@import ../../environment

.pagination
  --bs-pagination-border-width: 0
  --bs-pagination-bg: var(--black)
  --bs-pagination-color: var(--interaction-secondary-disabled)
  --bs-pagination-active-bg: var(--ui-highlight-primary-60)
  --bs-pagination-active-color: var(--ui-primary)
  --bs-pagination-font-size: 12px
  --bs-pagination-line-height: 17px
  --circle-size: 23px
  display: flex
  place-content: center
  margin-top: calc(-1 * var(--table-margin-bottom))
  padding-block: var(--spacer-16)
  flex-wrap: wrap

  background: var(--bs-pagination-bg)
  border-top: var(--border-width-default) solid var(--background-level-1)

  .page-item
    &:not(:first-child):not(:nth-child(2)):not(:last-child)
      // Resizing the pagination broke their positioning
      // This ensures we have enough spacing _between_ two page numbers
      margin-left: var(--spacer-16)

  .page-link
    display: flex
    width: var(--circle-size)
    height: var(--circle-size)
    place-content: center
    align-items: center
    padding: 0

    border-radius: 50%

  // Overwrite styles from _pagination.scss
  &.disabled, .disabled > .page-link
    background: none

  // customize arrows
  .page-item:first-child,
  .page-item:last-child
    &:not(.disabled) .page-link
      color: var(--bs-pagination-active-color)

    .page-link
      background: none

  .page-item:first-child
    margin-left: var(--spacer-20)
    flex: 1 0

  .page-item:last-child
    margin-right: var(--spacer-20)
    flex: 1 0
    display: flex
    place-content: flex-end
