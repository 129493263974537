@import ../../environment

/* Active MaterialUI styling for every bootstrap form group */
.form-group
  &.country_selection,
  &.date,
  &.datetime,
  &.decimal,
  &.email,
  &.email_with_icon,
  &.grouped_select,
  &.integer,
  &.password,
  &.select,
  &.string,
  &.string_with_dropdown,
  &.text,
  &.url,
  &.file,
  &.reference_impact_chooser,
  &.select_or_new
    position: relative
    +transition-after-page-load(top var(--transition-duration-default) ease-out)

    --small-label-font-size: 0.75rem
    --small-label-height: var(--line-height-copy-mini)
    --input-line-height: var(--line-height-copy)

    &:not(.hidden)
      margin-bottom: var(--form-group-spacer)

      &:last-child,
      .col-form-label > &,
      .form-text > &
        margin-bottom: 0

    .form-control
      height: var(--input-line-height)

    .form-control:not(.ts-wrapper):not(textarea),
    .ts-control,
    .form-label
      +truncate-text-overflow

    .form-label
      width: calc(100% - 2 * var(--input-horizontal-padding))

      +within-unit-selection-field
        display: none
        +only-in-tests
          display: flex

    .form-control:not(.ts-wrapper),
    .ts-control
      font-size: var(--input-font-size)
      line-height: var(--input-line-height)

    .form-label
      // left and top can be overridden for .input-group by `form_group.js`
      position: absolute
      left: var(--input-horizontal-padding)
      margin: 0
      top: calc(var(--input-height) / 2 - var(--input-line-height) / 2)
      z-index: 3 // Overtops the input field and the arrow area in select fields

      font-size: var(--input-font-size)
      line-height: var(--input-line-height)

      pointer-events: none
      +transition-after-page-load(var(--transition-duration-default) ease-out)

      // Keep in sync with the icon size in .ts_control.sass (::after)
      --select-arrow-size: 20px

      &.-for-select
        padding-right: var(--input-select-space-to-toggle-icon)

    .field-with-suggestion &
      margin-bottom: var(--spacer-8)
      /* Suggestions (and placeholders for them) should not be shown when the user is selecting a value from the field in question */
      &:has(.dropdown-active)
        margin-bottom: 0

    &.-focused,
    &.-with-value
      .form-control:not(.ts-wrapper),
      .ts-control
        --padding-top: calc(var(--input-padding-top) + var(--small-label-height))

      .form-control.text
        --padding-top: calc(var(--input-padding-top) + var(--small-label-height) + var(--spacer-4))

      +within-unit-selection-field
        .ts-control
          --padding-top: 0

      .form-control:not(.ts-wrapper)
        padding-top: var(--padding-top)

      .ts-control
        padding: var(--padding-top) var(--input-horizontal-padding) var(--input-padding-bottom) !important

      .form-label
        height: var(--small-label-height)
        top: var(--input-padding-top)

        &.text
          top: 0
          padding-top: var(--input-padding-top)
          height: calc(var(--small-label-height) + var(--input-padding-top))

        font-size: var(--small-label-font-size)
        line-height: var(--small-label-height)

  &.check_boxes > .invalid-feedback
    padding-inline: 0

  .copy
    cursor: pointer
    position: absolute
    right: var(--input-horizontal-padding)
    top: calc(var(--input-height) / 2 - var(--small-icon-size) / 2)
    opacity: 0
    z-index: 5

    .dynamic-row &
      right: 0

  &:hover .copy
    opacity: 1
    transition: opacity var(--transition-duration-default)


  &.-with-mismatch-warning
    .form-label
      pointer-events: auto
      .icon
        margin: -2px 0 0

  &.open-in-modal
    cursor: pointer
    max-width: var(--default-column-width)
    .subform--col.-large &
      max-width: var(--large-column-width)

    .form-control,
    .form-select
      &.disabled
        +text-link
        pointer-events: none
