@import ../../environment

// Default scrollbars are not well suited for dark mode sites
::-webkit-scrollbar
  background-color: var(--ui-primary)
::-webkit-scrollbar-thumb
  background-color: var(--ui-highlight-primary)

html
  +scrollbar-color(var(--ui-highlight-primary) transparent)
