.form-check-input
  cursor: pointer

  &:disabled
    cursor: not-allowed


.form-check-label
  cursor: pointer

  input:disabled ~ &
    cursor: not-allowed


.form-check
  .col-form-label &,
  .input-group-text &
    margin-bottom: 0

  .icon
    margin-left: var(--spacer-8)
