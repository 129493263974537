@import ../environment

.substitution-row-impact
  display: flex
  gap: var(--spacer)
  align-items: center

.substitution-row-impact--new-impact-label
  flex: 0 0 auto

.substitution-row-impact--new-impact-costs
  flex: 0 0 auto

  &.-positive
    color: var(--ui-success)

  &.-negative
    color: var(--ui-warning)

  +within-deleted-row
    color: var(--interaction-secondary-disabled)

.substitution-row-impact--old-impact-costs
  flex: 0 0 auto
  color: var(--interaction-secondary-disabled)
