@import ../environment

.environment-banner
  --height: var(--banner-height, var(--spacer-16))
  height: var(--height)
  line-height: var(--height)

  text-align: center
  z-index: 9000
  +bold
  font-size: .875rem

  background-image: linear-gradient(45deg,rgba(255,255,0,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)
  background-size: 5rem 5rem
  border-bottom: var(--border-width-default) solid rgba(0, 0, 0, .15)

  letter-spacing: .5px
  +uppercase

  &::before
    content: attr(data-environment)

  &[data-environment="development"]
    background-color: var(--bs-dark)
    color: var(--bs-white)

  &[data-environment="integration"]
    background-color: var(--bs-orange)

  &[data-environment="staging"]
    background-color: var(--bs-yellow)

  &[data-environment="production"]
    background-color: var(--bs-danger)
    color: var(--bs-white)
