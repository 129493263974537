:root {
  --border-radius: 0;
  --multiselect-item-border-radius: 16px;
  --tile-border-radius: 20px;
  --border-width-default: 1px;
  --border-width-active: 3px;
  --outline-width-default: 1px;
  --outline-offset-focus: 2px;
  --max-border-radius: 50%;
  --tab-group-border-width: 0;
  --tab-group-border-width-bottom: 3px;
  --tab-group-border-color: var(--white);
  --kpi-tile-border-radius: 5px;
  --kpi-active-tile-border-width: 2px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbmZpZy9yb290Iiwic291cmNlcyI6WyJfYm9yZGVycy5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFDO0VBQ0M7RUFDQTtFQUNBO0VBRUE7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUVBO0VBQ0E7RUFDQTtFQUVBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJcXDpyb290XG4gIC0tYm9yZGVyLXJhZGl1czogMFxuICAtLW11bHRpc2VsZWN0LWl0ZW0tYm9yZGVyLXJhZGl1czogMTZweFxuICAtLXRpbGUtYm9yZGVyLXJhZGl1czogMjBweFxuXG4gIC0tYm9yZGVyLXdpZHRoLWRlZmF1bHQ6IDFweFxuICAtLWJvcmRlci13aWR0aC1hY3RpdmU6IDNweFxuICAtLW91dGxpbmUtd2lkdGgtZGVmYXVsdDogMXB4XG4gIC0tb3V0bGluZS1vZmZzZXQtZm9jdXM6IDJweFxuICAtLW1heC1ib3JkZXItcmFkaXVzOiA1MCVcblxuICAtLXRhYi1ncm91cC1ib3JkZXItd2lkdGg6IDBcbiAgLS10YWItZ3JvdXAtYm9yZGVyLXdpZHRoLWJvdHRvbTogM3B4XG4gIC0tdGFiLWdyb3VwLWJvcmRlci1jb2xvcjogdmFyKC0td2hpdGUpXG4gIFxuICAtLWtwaS10aWxlLWJvcmRlci1yYWRpdXM6IDVweFxuICAtLWtwaS1hY3RpdmUtdGlsZS1ib3JkZXItd2lkdGg6IDJweFxuIl19 */