@import ../variables/breakpoints

\:root
  --banner-height: 0px
  --collapsed-sidebar-width: 68px
  --content-border: 15px
  --content-max-width: 1320px
  --content-padding: clamp(40px, 5vw, 80px)
  --content-padding-mobile: 20px
  --layout-max-width: 2560px
  --expanded-sidebar-min-width: 320px
  --expanded-sidebar-max-width: 500px
  --general-info-form-width: 540px
  --mobile-top-bar-height: 72px

[data-environment-banner]
  --banner-height: 22px
