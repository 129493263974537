.empty-content
  --icon-margin: 0

  display: flex
  flex-direction: column
  gap: var(--spacer-32)
  text-align: center

  &.-small
    --icon-margin: 10px
    gap: var(--spacer-12)

.empty-content--icon
  --icon-margin-top: var(--icon-margin)
  --icon-margin-bottom: var(--icon-margin)
  --icon-color: var(--white)

.empty-content--title
  color: var(--ui-primary)
  margin: 0

  .empty-content.-small &
    font-size: var(--font-size-copy-mini)

.empty-content--description
  color: var(--ui-secondary-disabled)
  letter-spacing: var(--letter-spacing-copy-wide)
  text-wrap: balance
