@import ../environment

.subform-row-delete--icon
  .-marker-active &
    color: var(--interaction-primary-active)

  &.-disabled
    color: var(--interaction-secondary-disabled)

.subform-row-delete--checkbox
  display: none
  +only-in-tests
    display: block
