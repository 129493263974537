.action {
  display: grid;
  place-items: center;
  width: var(--action-width);
}

.action--container {
  align-items: center;
  display: flex;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImFjdGlvbi5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBOzs7QUFFRjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuYWN0aW9uXG4gIGRpc3BsYXk6IGdyaWRcbiAgcGxhY2UtaXRlbXM6IGNlbnRlclxuICB3aWR0aDogdmFyKC0tYWN0aW9uLXdpZHRoKVxuXG4uYWN0aW9uLS1jb250YWluZXJcbiAgYWxpZ24taXRlbXM6IGNlbnRlclxuICBkaXNwbGF5OiBmbGV4XG4iXX0= */