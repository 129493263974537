// We opted out of RFS as it may potentially scale unwanted elements if we are not careful
// https://github.com/twbs/rfs
// Instead, we define a fixed set of font-sizes and line-heights which will change on
// different breakpoints

@import ../variables

\:root
  // on XS
  --headings-font-family: Gilroy
  --headings-font-family-bolder: Gilroy Semibold
  --headings-font-weight: regular

  --font-size-h1: 34px
  --font-size-h2: 28px
  --font-size-h3: 20px
  --font-size-h4: 16px

  --line-height-h1: 45px
  --line-height-h2: 45px
  --line-height-h3: 28px
  --line-height-h4: 23px

  // copy text
  --font-family-copy: $font-family-sans-serif

  --font-size-copy: 16px
  --font-size-copy-small: 13px
  --font-size-copy-mini: 12px

  --line-height-copy: 23px
  --line-height-copy-small: 20px
  --line-height-copy-mini: 18px

  --letter-spacing-copy-xxl: 1.5px
  --letter-spacing-copy-wide: 0.48px
  --letter-spacing-copy-medium: 0.36px

  // buttons & inputs
  --button-font-weight: 400
  --button-font-family: var(--headings-font-family-bolder)
  --button-font-size: var(--font-size-copy)
  --button-line-height: calc(var(--button-height) - 2 * var(--button-vertical-padding))
  --button-letter-spacing: 0.12rem
  --input-font-size: var(--font-size-copy)

  --lca-tile-progress-font-size: 25px
  
  --kpi-expanded-tile-record-count-font-size: 48px

  // modal
  --modal-header-line-height: 36px
  --modal-header-letter-spacing: 0.84px
  --modal-section-header-letter-spacing: 0.6px
