@import ../environment

.subform
  +wizard-stage-form

  @for $i from 1 through 2
    &.-with-action-count-#{$i}
      .scroller
        +on-non-mobile-screens
          --fading-area-offset-end: calc(#{$i} * var(--action-width))
          margin-right: calc(-1 * var(--wizard-stage-form-padding))

  .scroller
    --fading-area-size: 0
    --fading-border-area-size-start: 0

    .scroller--button
      --scroller-button-offset: 0

.subform--row
  --default-column-width: 200px
  --large-column-width: 340px
  --action-column-width: 50px

  display: flex

  .subform.-with-header &:first-child
    padding-top: var(--spacer-24)

  margin-top: var(--spacer-20)

  &:first-child
    margin-top: 0

  &.-hidden
    display: none

.subform--col
  // Keep the same width for all items
  flex-basis: var(--default-column-width)
  flex-grow: 1
  flex-shrink: 0

  .subform--row[disabled] &
    opacity: 0.3

  &.-large
    flex-basis: var(--large-column-width)

  & + &
    margin-left: var(--spacer-16)

.subform--actions
  display: flex

  +on-non-mobile-screens
    position: sticky
    right: 0
    z-index: 3

  background-color: var(--background-level-2)

.subform--action
  display: grid
  place-items: center
  height: var(--input-height)
  width: var(--action-column-width)
