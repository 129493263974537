@import ../../environment

.form-select
  +input-like
  +only-bottom-border
  +copy-semibold

  height: var(--input-height) !important

  &[disabled]
    background-color: var(--input-background-color)
    border-bottom: 0
    color: var(--interaction-secondary-disabled)
