@import ../environment

.subform-header
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  gap: var(--spacer-16)
  margin-bottom: var(--spacer-24)

.subform-header--title
  +copy-semibold
  +copy
