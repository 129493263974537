@import ../environment

.diagram
  background-color: var(--black)

.diagram--label
  padding: var(--spacer-8) var(--spacer-20) 0

.diagram--selection
  padding: 0 var(--spacer-20) var(--spacer-8)
  border-bottom: var(--input-border-height) solid var(--input-border-color)

.diagram--content
  padding: var(--spacer-20)
