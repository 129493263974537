@import ../../environment

.accordion
  --bs-accordion-bg: var(--background-level-2)
  --bs-accordion-active-bg: var(--background-level-2)
  --bs-accordion-active-color: var(--bs-body-color)
  --bs-accordion-border-color: var(--input-border-color)
  --bs-accordion-border-width: 2px
  --bs-accordion-btn-focus-box-shadow: none
  --bs-accordion-body-padding-x: 1rem
  --bs-accordion-body-padding-y: 1rem
  --bs-accordion-btn-padding-x: 1rem
  --bs-accordion-btn-padding-y: 0

  --accordion-item-distance: 2rem

  +only-in-tests
    --bs-accordion-btn-icon-transition: none

.accordion-button
  +small-icon-url(accordion-active)
  --bs-accordion-btn-active-icon: var(--icon-url)

  border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color)

  &:not(.collapsed)
    box-shadow: none

  &.collapsed
    +small-icon-url(accordion)
    --bs-accordion-btn-icon: var(--icon-url)

.accordion-item
  border: none

  & + &
    margin-top: var(--accordion-item-distance)

.accordion-body
  background-color: var(--white-level-0)

  +within-modal
    background-color: var(--background-level-0)

.collapsing
  +only-in-tests
    transition: none
    transform: none
