@import ../environment

.header-actions
  display: grid
  grid-template-areas: 'back-link . further-actions'
  grid-template-columns: minmax(0, 1fr) 1fr auto
  margin-bottom: var(--spacer-40)

.header-actions--go-back
  display: flex
  grid-area: back-link
  --icon-margin-right: 4px /* Actually rendered as a 10px gap because of SVG-foo */

.header-actions--go-back-text
  +text-link

.header-actions--further-actions
  grid-area: further-actions
  display: flex

.header-actions--icon-link
  margin-left: var(--spacer-24)

  +active-hover
    color: var(--link-hover-color)

.header-actions--inactive-icon-edit-lca
  color: var(--interaction-secondary-disabled)
  display: none

+when-page-has-dirty-form
  .header-actions--active-icon-edit-lca
    display: none

  .header-actions--inactive-icon-edit-lca
    display: block

    &:hover
      color: var(--interaction-secondary-disabled)
