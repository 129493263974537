.file-input--preview
  &[src='//:0']
    display: none

  &[src*='.svg']
    // SVGs without a `width` would render with 0px, even if they have a `viewBox` attribute. `width: 100%` fixes that.
    width: 100%

[file-input--filename]
  small
    display: none
