@import ../../environment

.input-group-text
  +only-bottom-border
  color: var(--ui-secondary)

  // Prepended text is usually already padded by the following element
  &:first-child
    padding-right: 0

  [disabled] + &
    color: var(--interaction-secondary-disabled)
    border-bottom: 0

  .is-invalid + &
    border-color: var(--bs-form-invalid-border-color)

  // Align addon background with the input
  background: var(--input-background-color)
