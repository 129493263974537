@import ../config/mixins

.modal-hint
  text-align: center
  --modal-hint-question-color: #{$brand-stone}

.modal-hint--icon
  +wiggle-animation

.modal-hint--title
  margin-block: var(--spacer-32)

.modal-hint--question
  margin-bottom: var(--spacer-32)
  color: var(--modal-hint-question-color)

.modal-hint--actions
  margin-top: var(--spacer-48)

.modal-hint--list
  text-align: left

.modal-hint--vertical-spacer
  margin-bottom: var(--spacer-32)
