.input-group
  flex-wrap: nowrap
  min-width: var(--input-min-width)
  width: auto

  & > .form-control:focus,
  & > .form-select:focus,
  & > .form-floating:focus-within
    z-index: auto

  .was-validated & > .form-control:not(:focus):invalid,
  & > .form-control:not(:focus).is-invalid,
  .was-validated & > .form-select:not(:focus):invalid,
  & > .form-select:not(:focus).is-invalid,
  .was-validated & > .form-floating:not(:focus-within):invalid,
  & > .form-floating:not(:focus-within).is-invalid
    z-index: auto

  &.is-invalid
    flex-wrap: wrap
