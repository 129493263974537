:root {
  --banner-height: 0px;
  --collapsed-sidebar-width: 68px;
  --content-border: 15px;
  --content-max-width: 1320px;
  --content-padding: clamp(40px, 5vw, 80px);
  --content-padding-mobile: 20px;
  --layout-max-width: 2560px;
  --expanded-sidebar-min-width: 320px;
  --expanded-sidebar-max-width: 500px;
  --general-info-form-width: 540px;
  --mobile-top-bar-height: 72px;
}

[data-environment-banner] {
  --banner-height: 22px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbmZpZy9yb290Iiwic291cmNlcyI6WyJfbGF5b3V0LnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUM7RUFDQztFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFFRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAuLi92YXJpYWJsZXMvYnJlYWtwb2ludHNcblxuXFw6cm9vdFxuICAtLWJhbm5lci1oZWlnaHQ6IDBweFxuICAtLWNvbGxhcHNlZC1zaWRlYmFyLXdpZHRoOiA2OHB4XG4gIC0tY29udGVudC1ib3JkZXI6IDE1cHhcbiAgLS1jb250ZW50LW1heC13aWR0aDogMTMyMHB4XG4gIC0tY29udGVudC1wYWRkaW5nOiBjbGFtcCg0MHB4LCA1dncsIDgwcHgpXG4gIC0tY29udGVudC1wYWRkaW5nLW1vYmlsZTogMjBweFxuICAtLWxheW91dC1tYXgtd2lkdGg6IDI1NjBweFxuICAtLWV4cGFuZGVkLXNpZGViYXItbWluLXdpZHRoOiAzMjBweFxuICAtLWV4cGFuZGVkLXNpZGViYXItbWF4LXdpZHRoOiA1MDBweFxuICAtLWdlbmVyYWwtaW5mby1mb3JtLXdpZHRoOiA1NDBweFxuICAtLW1vYmlsZS10b3AtYmFyLWhlaWdodDogNzJweFxuXG5bZGF0YS1lbnZpcm9ubWVudC1iYW5uZXJdXG4gIC0tYmFubmVyLWhlaWdodDogMjJweFxuIl19 */