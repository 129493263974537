@import testing

=bold
  font-weight: bold

=copy
  font-size: var(--font-size-copy)
  line-height: var(--line-height-copy)

=copy-small
  font-size: var(--font-size-copy-small)
  line-height: var(--line-height-copy-small)

=copy-mini
  font-size: var(--font-size-copy-mini)
  line-height: var(--line-height-copy-mini)

=copy-semibold
  font-family: var(--font-family-copy)
  font-weight: 600

=heading($level)
  font-family: var(--headings-font-family)
  font-weight: var(--headings-font-weight)
  font-size: var(--font-size-#{$level})
  line-height: var(--line-height-#{$level})
  letter-spacing: 0.03em
  color: var(--ui-primary)
  hyphens: auto /* break overly long headlines natively */

=h1
  +heading(h1)

=h2
  +heading(h2)

=h3
  +heading(h3)
  font-family: var(--headings-font-family-bolder)

=h4
  +heading(h4)
  font-family: var(--headings-font-family-bolder)

=uppercase
  text-transform: uppercase
  +only-in-tests
    // Capybara does not ignore text-transform - this makes it easier to find elements by their text
    text-transform: none

=text-link
  display: inline
  +copy
  +bold
  text-decoration-line: underline
  text-underline-offset: 4px
  letter-spacing: var(--letter-spacing-copy-wide)

=button-typography
  font-weight: var(--button-font-weight)
  font-family: var(--button-font-family)
  font-size: var(--button-font-size)
