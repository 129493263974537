@import ../../environment

.form-text
  padding-inline: var(--spacer-16)

  color: var(--info-text-color)
  +copy-mini

  .form-group.boolean &
    padding-left: 0
