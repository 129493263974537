.form-check-input {
  cursor: pointer;
}
.form-check-input:disabled {
  cursor: not-allowed;
}

.form-check-label {
  cursor: pointer;
}
input:disabled ~ .form-check-label {
  cursor: not-allowed;
}

.col-form-label .form-check, .input-group-text .form-check {
  margin-bottom: 0;
}
.form-check .icon {
  margin-left: var(--spacer-8);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbXBvbmVudHMvYm9vdHN0cmFwX2V4dCIsInNvdXJjZXMiOlsiZm9ybV9jaGVjay5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7O0FBRUE7RUFDRTs7O0FBR0o7RUFDRTs7QUFFQTtFQUNFOzs7QUFJRjtFQUVFOztBQUVGO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuZm9ybS1jaGVjay1pbnB1dFxuICBjdXJzb3I6IHBvaW50ZXJcblxuICAmOmRpc2FibGVkXG4gICAgY3Vyc29yOiBub3QtYWxsb3dlZFxuXG5cbi5mb3JtLWNoZWNrLWxhYmVsXG4gIGN1cnNvcjogcG9pbnRlclxuXG4gIGlucHV0OmRpc2FibGVkIH4gJlxuICAgIGN1cnNvcjogbm90LWFsbG93ZWRcblxuXG4uZm9ybS1jaGVja1xuICAuY29sLWZvcm0tbGFiZWwgJixcbiAgLmlucHV0LWdyb3VwLXRleHQgJlxuICAgIG1hcmdpbi1ib3R0b206IDBcblxuICAuaWNvblxuICAgIG1hcmdpbi1sZWZ0OiB2YXIoLS1zcGFjZXItOClcbiJdfQ== */