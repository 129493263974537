.file-input--preview[src="//:0"] {
  display: none;
}
.file-input--preview[src*=".svg"] {
  width: 100%;
}

[file-input--filename] small {
  display: none;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImZpbGVfaW5wdXQuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFOztBQUVGO0VBRUU7OztBQUdGO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuZmlsZS1pbnB1dC0tcHJldmlld1xuICAmW3NyYz0nLy86MCddXG4gICAgZGlzcGxheTogbm9uZVxuXG4gICZbc3JjKj0nLnN2ZyddXG4gICAgLy8gU1ZHcyB3aXRob3V0IGEgYHdpZHRoYCB3b3VsZCByZW5kZXIgd2l0aCAwcHgsIGV2ZW4gaWYgdGhleSBoYXZlIGEgYHZpZXdCb3hgIGF0dHJpYnV0ZS4gYHdpZHRoOiAxMDAlYCBmaXhlcyB0aGF0LlxuICAgIHdpZHRoOiAxMDAlXG5cbltmaWxlLWlucHV0LS1maWxlbmFtZV1cbiAgc21hbGxcbiAgICBkaXNwbGF5OiBub25lXG4iXX0= */