@import ../environment

.kpi-selection
  display: flex
  flex-direction: column
  height: 100%

.kpi-selection--content

  .kpi-selection:not(.-expanded) &
    display: none
