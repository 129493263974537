@import ../environment

up-modal-viewport
  up-modal[nesting="0"] &
    +within-unauthenticated-layout
      --logo-padding: calc(var(--unauthenticated-logo-height) + 2 * var(--unauthenticated-logo-top))
      padding-top: var(--logo-padding)
      +on-non-mobile-screens
        padding-block: var(--logo-padding)

  up-modal.-for-instant-modal &
    align-items: center

up-modal-box
  --padding: var(--spacer-48)
  max-height: 100%
  padding: var(--padding)
  border-radius: var(--border-radius)
  box-shadow: var(--box-shadow)

  +on-mobile-screens
    --padding: var(--spacer-20)

  up-modal[size='small'] &
    width: var(--container-max-sm)
  up-modal[size='medium'] &
    width: var(--container-max-md)
  up-modal[size='large'] &
    width: var(--container-max-lg)
  up-modal[size='super-large'] &
    width: 1000px
  up-modal[size='max-container'] &
    max-width: var(--container-max-xxl)

up-drawer-box
  height: 100vh

  .lca-stage-help &
    padding: var(--spacer-40) var(--spacer-48)
    width: 550px

    background-color: var(--ui-info)

  up-drawer[size='medium'] &
    width: 550px

up-drawer-content
  height: 100%

up-modal-box, up-drawer-box, up-cover-box, up-popup
  background-color: $black

up-modal-dismiss,
up-drawer-dismiss
  color: var(--white)

  &::before
    content: ''
    +icon(modal-close, $size: 20)

up-modal-dismiss
  top: var(--spacer-16)
  right: var(--spacer-16)

  up-modal-content:has(.non-dismissable-modal) + &
    display: none

up-drawer-dismiss
  top: 0
  right: 0
  padding: var(--spacer-40) var(--spacer-48)

up-progress-bar
  background-color: var(--bs-primary)
  box-shadow: -3px 0 2px 2px rgba(var(--bs-white-rgb), 80%)

up-drawer-box,
up-cover-box,
up-popup
  padding: calc(var(--grid-gutter-width) / 2)

up-modal-backdrop
  background: rgba(255, 255, 255, 0.10)
  backdrop-filter: blur(20px)
