@import ../environment

.responsive-tier-detector
  position: absolute
  height: 0
  width: 0

  font-family: 'viewport-desktop'
  +on-tablet-screens
    font-family: 'viewport-tablet'
  +on-mobile-screens
    font-family: 'viewport-mobile'
