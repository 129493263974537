@import ../environment

.dashboard
  display: flex
  flex-direction: column
  height: 100%

.dashboard--content
  display: flex
  flex-direction: column
  flex-grow: 1
  gap: var(--spacer-48)
