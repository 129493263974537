@import ../environment

.main-navigation
  // Until we implement the sidebar navigation we have to decrease the main navigation size
  --bs-nav-link-font-size: .7rem
  --small-icon-size: 12px

  +blurring-background($black, $transparency-level-2)
  border-bottom: var(--border-width-default) solid rgba($border-color, $transparency-level-2)
  box-shadow: var(--box-shadow-sm)

.main-navigation--logo
  // The logo has an aspect ratio of 30:43
  // We have to scale it accordingly
  --aspect-ratio: 30/43
  --height: 30px
  height: var(--height)
  width: calc(var(--height) * var(--aspect-ratio))
