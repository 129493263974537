.empty-table {
  margin-top: calc(-1 * var(--table-margin-bottom));
  background-color: var(--bs-table-bg);
}

.empty-table--body {
  padding: 200px var(--spacer-40);
}
.empty-table.-small .empty-table--body {
  padding: var(--spacer-24) 0 0 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImVtcHR5X3RhYmxlLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBOzs7QUFFRjtFQUNFOztBQUVBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuZW1wdHktdGFibGVcbiAgbWFyZ2luLXRvcDogY2FsYygtMSAqIHZhcigtLXRhYmxlLW1hcmdpbi1ib3R0b20pKVxuICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1icy10YWJsZS1iZylcblxuLmVtcHR5LXRhYmxlLS1ib2R5XG4gIHBhZGRpbmc6IDIwMHB4IHZhcigtLXNwYWNlci00MClcblxuICAuZW1wdHktdGFibGUuLXNtYWxsICZcbiAgICBwYWRkaW5nOiB2YXIoLS1zcGFjZXItMjQpIDAgMCAwXG4iXX0= */