@import ../environment

=support-width-animation
  overflow-x: hidden
  white-space: nowrap

=hidden-on-mobile-while-collapsed
  +on-mobile-screens
    +when-sidebar-collapsed
      display: none

.sidebar
  --sidebar-top-padding: calc(var(--spacer-32) + var(--banner-height))
  --sidebar-inline-padding: var(--spacer-24)
  // var(--spacer-40) as soon as at least one item is added to _bottom_menu.haml
  --bottom-menu-top-spacing: 0
  // var(--spacer-32) as soon as at least one item is added to _bottom_menu.haml
  --bottom-menu-mobile-top-spacing: 0
  // var(--border-width-default) as soon as at least one item is added to _bottom_menu.haml
  --divider-collapsed-height: 0

  display: grid
  max-height: calc(100dvh - var(--banner-height))
  z-index: 1

  padding: var(--sidebar-top-padding) 0 var(--spacer-20)

  background-color: var(--background-level-0)

  +on-non-mobile-screens
    grid-template-areas: ". root ." ". . button" "main-menu-start . main-menu-end" ". . ." ". bottom-menu ." ". . ." ". divider ." ". . ." ". profile ."
    grid-template-columns: var(--sidebar-inline-padding) 1fr var(--sidebar-inline-padding)
    grid-template-rows: auto var(--spacer-48) 1fr var(--bottom-menu-top-spacing) auto var(--spacer-16) var(--divider-collapsed-height) var(--spacer-16) auto

    height: calc(100vh - var(--banner-height))

  +on-mobile-screens
    --sidebar-inline-padding: var(--spacer-20)

    align-items: center
    grid-template-areas: ". root . title . button ."
    grid-template-columns: var(--sidebar-inline-padding) auto 1fr auto 1fr auto var(--sidebar-inline-padding)
    grid-template-rows: auto
    height: var(--mobile-top-bar-height)

    padding: var(--spacer-16) 0 var(--spacer-20)

  &.-expanded
    +on-non-mobile-screens
      grid-template-areas: ". root ." ". . button" "main-menu-start . main-menu-end" ". . ." ". bottom-menu ." ". . ." ". mini-links ." ". . ." ". divider ." ". . ." ". profile ."
      grid-template-rows: auto var(--spacer-48) 1fr var(--bottom-menu-top-spacing) auto var(--spacer-16) auto var(--spacer-16) 1px var(--spacer-16) auto

    +on-mobile-screens
      grid-template-areas: ". root . title . button ." ". . . . . . ." "main-menu-start . . . . . main-menu-end" ". . . . . . ." ". bottom-menu-start . . . bottom-menu-end ." ". . . . . . ." ". mini-links-start . . . mini-links-end ." ". . . . . . ." ". divider-start . . . divider-end ." ". . . . . . ." ". profile-start . . . profile-end ."
      grid-template-rows: auto var(--spacer-20) 1fr var(--bottom-menu-mobile-top-spacing) auto var(--spacer-16) auto var(--spacer-16) 1px var(--spacer-16) auto
      height: 100vh

.sidebar--root
  grid-area: root

.sidebar--toggle-button
  align-self: center
  grid-area: button
  justify-self: flex-end

.sidebar--main-menu
  grid-column: main-menu-start / main-menu-end
  grid-row: main-menu-start / main-menu-end
  height: 100%

  &.scroller
    --fading-area-size-start: 0
    --fading-area-size-end: 100px
    --fading-border-area-size-start: 0

  +support-width-animation
  +hidden-on-mobile-while-collapsed

.sidebar--bottom-menu
  +on-non-mobile-screens
    grid-area: bottom-menu

  +on-mobile-screens
    grid-column: bottom-menu-start / bottom-menu-end
    grid-row: bottom-menu-start / bottom-menu-end

  +support-width-animation
  +hidden-on-mobile-while-collapsed

.sidebar--mini-links
  +on-non-mobile-screens
    grid-area: mini-links

  +on-mobile-screens
    grid-column: mini-links-start / mini-links-end
    grid-row: mini-links-start / mini-links-end

  +support-width-animation

  +when-sidebar-collapsed
    display: none

.sidebar--divider
  height: 100%

  +on-non-mobile-screens
    grid-area: divider

  +on-mobile-screens
    grid-column: divider-start / divider-end
    grid-row: divider-start / divider-end

  background-color: var(--ui-primary)
  +hidden-on-mobile-while-collapsed

.sidebar--profile
  +on-non-mobile-screens
    grid-area: profile

  +on-mobile-screens
    grid-column: profile-start / profile-end
    grid-row: profile-start / profile-end

  +support-width-animation
  +hidden-on-mobile-while-collapsed
