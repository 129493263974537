@import ../variables
@import ../mixins

// Only ever use colors listed below. This enables us to easily implement a light mode if necessary.
// Note that bootstrap itself still relies on SASS precompilation.
// Changes should be synced to variables/_colors.sass
\:root
  --ui-primary: #{$ui-primary}
  --ui-secondary: #{$ui-secondary}
  --ui-secondary-disabled: #{$ui-secondary-disabled}

  --ui-success: #{$ui-success}
  --ui-warning: #{$ui-warning}
  --ui-error: #{$ui-error}
  --ui-info: #{$ui-info}

  --ui-highlight-primary: #{$ui-highlight-primary}
  --ui-highlight-primary-rgb: #{hex-to-rgb($ui-highlight-primary)}
  --ui-highlight-primary-60: #{$ui-highlight-primary-60}
  --ui-highlight-primary-hover: #{$ui-highlight-primary-hover}
  --ui-highlight-primary-disabled: #{$ui-highlight-primary-disabled}

  --ui-highlight-secondary: #{$ui-highlight-secondary}

  --interaction-primary-default: #{$interaction-primary-default}
  --interaction-primary-hover: #{$interaction-primary-hover}
  --interaction-primary-active: #{$interaction-primary-active}
  --interaction-primary-disabled: #{$interaction-primary-disabled}

  --interaction-secondary-disabled: #{$brand-concrete}

  --background-level-0: #{$background-level-0}
  --background-level-1: #{$background-level-1}
  --background-level-2: #{$background-level-2}

  --transparency-level-0: #{$transparency-level-0}
  --transparency-level-1: #{$transparency-level-1}
  --transparency-level-2: #{$transparency-level-2}

  // UI usually mixes white with a few % transparency
  --white-level-0: #{$white-level-0}
  --white-level-1: #{$white-level-1}
  --white-level-2: #{$white-level-2}

  --link-hover-color: #{$brand-stone}

  --black: #{$black}
  --white: #{$white}
  --white-rgb: #{hex-to-rgb($white)}

  --avatar-background-color: #{$brand-stone}
  --info-text-color: #{$brand-concrete}
  --input-background-color: var(--background-level-2)

  --gradient-color-grey-highlight: #{$brand-gradient-neutral}
  --gradient-color-grey-dark: #{$brand-concrete}
  --gradient-color-violet-highlight: #{$brand-water-bright}
  --gradient-color-violet-dark: #{$brand-gradient-progressing-dark}
  --gradient-color-green-highlight: #{$brand-gradient-positive-highlight}
  --gradient-color-green-dark: #{$brand-gradient-positive-dark}

  --stage-configuration-primary: #{$brand-water-hover}
  --stage-configuration-secondary: #{$brand-water-bright}
  --stage-configuration-excluded: #{$brand-basalt}
