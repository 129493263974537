@import '../environment'

.detailed-cost-distribution-chart
  --chart-height: 750px

  height: var(--chart-height)
  background: var(--background-level-2)

  &.-scrolling-page
    pointer-events: none
