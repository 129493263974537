@import ../environment

.layout
  display: grid
  grid-template-areas: "sidebar content-wrapper"
  height: calc(100dvh - var(--banner-height))

  &.-for-expanded-sidebar
    +breakpoint-down(xxl)
      grid-template-columns: var(--expanded-sidebar-min-width) 1fr

    +breakpoint-up(xxl)
      grid-template-columns: min(25%, var(--expanded-sidebar-max-width)) 1fr

    +on-mobile-screens
      grid-template-areas: "sidebar"
      grid-template-columns: 100%
      grid-template-rows: 1fr

  &:not(.-for-expanded-sidebar)
    +on-non-mobile-screens
      grid-template-columns: var(--collapsed-sidebar-width) 1fr

    +on-mobile-screens
      grid-template-areas: "sidebar" "content-wrapper"
      grid-template-rows: var(--mobile-top-bar-height) 1fr

  &:has(.sidebar.-interacted)
    transition: all var(--transition-duration-default) ease

.layout--sidebar
  grid-area: sidebar

.layout--content-wrapper
  --space-filler-width: calc(100vw - #{var(--layout-max-width)})

  display: grid
  grid-area: content-wrapper
  grid-template-areas: "content space-filler"
  grid-template-columns: minmax(0, 1fr) var(--space-filler-width)
  height: 100%
  overflow-y: auto

  +on-non-mobile-screens
    border: var(--content-border) solid var(--background-level-0)
    border-left: 0 // The sidebar uses the same color as this border

.layout--content
  grid-area: content

  +on-non-mobile-screens
    padding: var(--content-padding)

  +on-mobile-screens
    padding: var(--content-padding-mobile)

.layout--space-filler
  grid-area: space-filler

  background-color: var(--background-level-0)
.layout--main
  height: 100%
  margin-inline: auto
  max-width: var(--content-max-width)
