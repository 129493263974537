.searchbar
  min-width: var(--input-min-width)
  max-width: var(--searchbar-max-width)
  background: transparent

  .form-control
    background: transparent

  .input-group-text
    background: transparent
    height: var(--input-height)

  &.-stretched
    max-width: 100%

.searchbar--search-icon
  --icon-color: var(--white)
