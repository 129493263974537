@import ../environment

.toggle-switch-with-label
  display: flex
  align-items: center
  gap: 8px

.toggle-switch-with-label.-disabled
  .toggle-switch-with-label--label
    color: var(--interaction-secondary-disabled)
