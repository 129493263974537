@import ../../environment

// Keep in sync with base/_spacers.sass
\:root
  --spacer-2: 2px
  --spacer-4: 4px
  --spacer-8: 8px
  --spacer-12: 12px
  --spacer-16: 16px
  --spacer-20: 20px
  --spacer-24: 24px
  --spacer-32: 32px
  --spacer-40: 40px
  --spacer-48: 48px
  --spacer-64: 64px
  --spacer-80: 80px
  --spacer-128: 128px

  --table-margin-bottom: var(--spacer-16)
  --unauthenticated-logo-top: var(--spacer-32)
  --unauthenticated-logo-height: 30px
  --unauthenticated-logo-left: var(--spacer-24)
