@import ../../environment

.btn
  --bs-btn-active-shadow: none
  --bs-gradient: none

  height: var(--button-height)
  max-width: clamp(0px, var(--button-width-max), 100%)

  &:not(.-icon-button):not(.btn-block)
    min-width: var(--button-width-min)

  +truncate-text-overflow-x
  +uppercase
  background-image: none
  letter-spacing: var(--button-letter-spacing)
  line-height: var(--button-line-height)

  &.-create-association,
  &.-edit-association
    --bs-btn-bg: var(--background-level-1)
    --bs-btn-active-bg: var(--background-level-1)
    --bs-btn-hover-bg: var(--input-border-color)
    --button-outline-border-width: 0
    --icon-color: var(--white)
    display: inline-grid
    place-content: center
    width: var(--button-create-association-width)

    +only-bottom-border

    &:disabled, &.disabled
      --bs-btn-bg: var(--background-level-2)
      --icon-color: var(--ui-secondary)

    .is-invalid ~ &
      border-bottom-color: var(--bs-form-invalid-border-color)

  &.-edit-association
    border-right: var(--border-width-default) solid var(--input-background-color)

.btn-primary
  --bs-btn-active-bg: var(--ui-highlight-primary)
  --bs-btn-hover-bg: var(--ui-highlight-primary-hover)

  --bs-btn-disabled-bg: var(--ui-highlight-primary-disabled)
  &:disabled, &.disabled, fieldset:disabled &
    filter: opacity(var(--transparency-level-2))

.btn-secondary
  --bs-btn-active-bg: var(--ui-highlight-secondary)
  --bs-btn-hover-bg: #{$brand-stone}

  --bs-btn-disabled-bg: #{$brand-concrete}
  --bs-btn-disabled-color: #{$black}

.btn-outline-secondary
  border-width: var(--button-outline-border-width)
  padding-top: calc(var(--button-vertical-padding) - var(--button-outline-border-width))
  padding-bottom: calc(var(--button-vertical-padding) - var(--button-outline-border-width))

  --bs-btn-active-bg: transparent
  --bs-btn-active-color: #{$white}

  --bs-btn-hover-color: #{$brand-stone}
  --bs-btn-hover-bg: transparent
  --bs-btn-hover-border-color: #{$brand-stone}

  --bs-btn-disabled-border-color: #{$brand-concrete}
  --bs-btn-disabled-color: #{$brand-concrete}

.btn-outline-danger
  border-width: var(--button-outline-border-width)
  padding-top: calc(var(--button-vertical-padding) - var(--button-outline-border-width))
  padding-bottom: calc(var(--button-vertical-padding) - var(--button-outline-border-width))
  --bs-btn-hover-border-color: #{$brand-fire}

.btn-block
  min-width: 100%
