.fancytree-container {
  background: var(--input-background-color);
  margin: var(--spacer-40) 0;
  width: 100%;
}
.fancytree-container thead {
  background: var(--background-level-1);
}
.fancytree-container thead th {
  color: var(--white);
  padding: var(--spacer-20);
  font-size: var(--font-size-h4);
  text-align: left;
  vertical-align: middle;
}
.fancytree-container .fancytree-node {
  display: flex !important;
  align-items: center;
}
.fancytree-container .fancytree-node .fancytree-title {
  color: var(--white);
  font-size: var(--input-font-size);
  line-height: 1.5;
}
.fancytree-container .fancytree-active, .fancytree-container .fancytree-focused {
  background-color: var(--ui-highlight-primary-hover) !important;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHAvYXNzZXRzL2FwcGxpY2F0aW9uL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImZhbmN5dHJlZS5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVKO0VBQ0U7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTs7QUFFSjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLmZhbmN5dHJlZS1jb250YWluZXJcbiAgYmFja2dyb3VuZDogdmFyKC0taW5wdXQtYmFja2dyb3VuZC1jb2xvcilcbiAgbWFyZ2luOiB2YXIoLS1zcGFjZXItNDApIDBcbiAgd2lkdGg6IDEwMCVcblxuICB0aGVhZFxuICAgIGJhY2tncm91bmQ6IHZhcigtLWJhY2tncm91bmQtbGV2ZWwtMSlcblxuICAgIHRoXG4gICAgICBjb2xvcjogdmFyKC0td2hpdGUpXG4gICAgICBwYWRkaW5nOiB2YXIoLS1zcGFjZXItMjApXG4gICAgICBmb250LXNpemU6IHZhcigtLWZvbnQtc2l6ZS1oNClcbiAgICAgIHRleHQtYWxpZ246IGxlZnRcbiAgICAgIHZlcnRpY2FsLWFsaWduOiBtaWRkbGVcblxuICAuZmFuY3l0cmVlLW5vZGVcbiAgICBkaXNwbGF5OiBmbGV4ICFpbXBvcnRhbnRcbiAgICBhbGlnbi1pdGVtczogY2VudGVyXG5cbiAgICAuZmFuY3l0cmVlLXRpdGxlXG4gICAgICBjb2xvcjogdmFyKC0td2hpdGUpXG4gICAgICBmb250LXNpemU6IHZhcigtLWlucHV0LWZvbnQtc2l6ZSlcbiAgICAgIGxpbmUtaGVpZ2h0OiAxLjVcblxuICAuZmFuY3l0cmVlLWFjdGl2ZSwgLmZhbmN5dHJlZS1mb2N1c2VkXG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tdWktaGlnaGxpZ2h0LXByaW1hcnktaG92ZXIpICFpbXBvcnRhbnRcblxuIl19 */