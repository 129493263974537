@import ../environment

.wizard-stages
  --nav-link-width: 180px

  +on-mobile-screens
    --nav-link-width: 64px

.wizard-stages--scroller
  place-content: center

  &.scroller
    --fading-area-size: 64px
    --fading-border-area-size: 0

.wizard-stages--active-title
  +bold
  color: var(--interaction-primary-hover)
  letter-spacing: 1px
  line-height: var(--line-height-copy-small)
  text-align: center
  +uppercase

  +on-non-mobile-screens
    display: none

.wizard-stages--nav-link
  flex-shrink: 0
