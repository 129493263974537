@import ../environment

.modal-section-header
  margin-bottom: var(--spacer-20)

.modal-section-header--title
  margin: 0
  word-wrap: break-word

  color: var(--ui-primary)
  font-family: var(--headings-font-family-bolder)
  font-size: var(--font-size-h3)
  line-height: var(--line-height-h3)
  letter-spacing: var(--modal-section-header-letter-spacing)

.modal-section-header--subtitle
  color: var(--ui-primary)
  letter-spacing: var(--letter-spacing-copy-medium)
  +copy-mini
