@import ../environment

.searchbar-container
  display: grid
  grid-template-columns: 1fr auto
  gap: var(--spacer-12)

.searchbar-container--start
  grid-row: 1
  grid-column: 1

.searchbar-container--end
  grid-row: 1
  grid-column: 2

  +on-mobile-screens
    display: none
