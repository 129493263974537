@import ../environment

.substitution-row
  --spacer: var(--spacer-20)
  display: grid
  grid-template-areas: "title" "selection" "impact"
  gap: var(--spacer)
  min-width: 100%
  margin-bottom: var(--spacer)

  +within-deleted-row
    color: var(--interaction-secondary-disabled)

.substitution-row--title
  grid-area: title
  +copy-semibold
  +copy

.substitution-row--selection
  grid-area: selection
  margin-left: var(--spacer)

.substitution-row--impact
  grid-area: impact
  margin-left: var(--spacer)
