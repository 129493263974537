@import ../environment

input
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    // Hide arrow buttons for number inputs
    // https://stackoverflow.com/a/22559163
    -webkit-appearance: none

  &.-uppercase
    +uppercase

  &.-unmodified-value
    color: var(--interaction-primary-active)

input[type="number"]
  // Firefox shows a very ugly spinner for numbers by default to allow users to click up and
  // down arrows to select a number. We don't want that.
  -moz-appearance: textfield
