@import ../environment

.suggestion
  width: 100%
  padding: 0 var(--spacer-16)

  display: grid
  grid-template-areas: "label . value"
  grid-template-columns: auto var(--spacer-8) minmax(0, 1fr)

  font-size: var(--font-size-copy-mini)

  /* Suggestions (and placeholders for them) should not be shown when the user is selecting a value from the field in question */
  .form-group:has(.dropdown-active) ~ &
    display: none

  &.-hidden
    display: none

.suggestion--label
  grid-area: label

  color: var(--ui-secondary)
  text-decoration: dotted

.suggestion--value
  width: fit-content
  max-width: 100%
  padding: 0 var(--spacer-12)

  grid-area: value

  border-radius: var(--multiselect-item-border-radius)
  background-color: var(--ui-highlight-primary)
  color: var(--white)
  cursor: pointer
  +truncate-text-overflow
