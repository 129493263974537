@import ../environment

.tab-group
  --tab-group-min-width: 200px
  --accent-color: var(--white)

  display: flex

  // both rules are necessary to support the tabs on the interpretation page as well as the tabs on the wizard pages
  & > a,
  & > .tab-group--tab
    flex: 1 1 0

  // necessary to remove double looking outline
  & a[href]:focus-visible
    outline: none

.tab-group-scroller
  +on-mobile-screens
    .scroller--content-container
      max-width: var(--content-max-width)
      width: 100%

.tab-group--tab
  min-width: var(--tab-group-min-width)

  +uppercase
  font-family: var(--headings-font-family-bolder)
  font-weight: var(--headings-font-weight)
  font-size: var(--font-size-copy)
  display: flex
  align-items: center
  place-content: center
  height: var(--button-height)
  line-height: calc(var(--button-line-height) - var(--tab-group-border-width-bottom) * 2)
  letter-spacing: 0.12rem
  background: transparent
  color: var(--accent-color)

  border: var(--tab-group-border-width) solid var(--tab-group-border-color)
  border-bottom: var(--tab-group-border-width-bottom) solid var(--tab-group-border-color)

  +on-mobile-screens
    padding: var(--spacer-24)

  &:hover
    --tab-group-border-color: var(--link-hover-color)

    color: var(--link-hover-color)

  &.-active
    background: var(--accent-color)
    color: var(--black)
    cursor: default

    &:hover
      --tab-group-border-color: var(--link-hover-color)

      background: var(--link-hover-color)

a:focus .tab-group--tab
  --tab-group-border-width: 3px
  --tab-group-border-color: var(--ui-highlight-primary)

a:focus .tab-group--tab.-active
  --tab-group-border-width: 1px
  --tab-group-border-width-bottom: 1px
  --tab-group-border-color: var(--ui-highlight-primary)
