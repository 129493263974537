@import ../variables/icons

=icon($icon, $size)
  +icon-without-url
  @if $size == 10
    +mini-icon-url($icon)
  @else if $size == 20
    +small-icon-url($icon)
  @else if $size == 40
    +medium-icon-url($icon)
  @else if $size == 60
    +large-icon-url($icon)
  @else
    @error "unknown size #{$size}"

=mini-icon-url($icon)
  $svg-filename: map-get($mini-icons, $icon)
  --size: var(--mini-icon-size)
  +icon-url($svg-filename, 10)

=small-icon-url($icon)
  $svg-filename: map-get($small-icons, $icon)
  --size: var(--small-icon-size)
  +icon-url($svg-filename, 20)

=medium-icon-url($icon)
  $svg-filename: map-get($medium-icons, $icon)
  --size: var(--medium-icon-size)
  +icon-url($svg-filename, 40)

=large-icon-url($icon)
  $svg-filename: map-get($large-icons, $icon)
  --size: var(--large-icon-size)
  +icon-url($svg-filename, 60)

=icon-url($svg-filename, $size)
  @if $svg-filename
    --icon-url: url("/app/app/assets/application/config/mixins/../../../images/icons/icons-#{$size}/type=#{$svg-filename}-#{$size}.svg")
  @else
    @error "SVG filename cannot be blank"

/* Base mixin that falls back to an empty SVG until a --icon-url is provided
=icon-without-url
  --icon-url: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="1" height="1"><rect width="1" height="1" fill="#000" /></svg>')
  --size: var(--size, var(--medium-icon-size))
  --margin-top: var(--icon-margin-top, 0)
  --margin-right: var(--icon-margin-right, 0)
  --margin-bottom: var(--icon-margin-bottom, 0)
  --margin-left: var(--icon-margin-left, 0)
  --mask: var(--icon-url) center / contain no-repeat

  display: inline-grid
  width: var(--size)
  height: var(--size)

  margin-top: var(--margin-top)
  margin-right: var(--margin-right)
  margin-bottom: var(--margin-bottom)
  margin-left: var(--margin-left)

  // Positioning icons correctly in a line is difficult.
  // Any common `vertical-align` value is not good enough, and choosing pixel or percent placements
  // depends on font size and icon size. Also, an incorrect value may impact the container's line height,
  // causing elements to grow larger than they should be.
  // Here is a list for perfect vertical alignment values, based on font and icon size.
  // 12px font size / 24px icon size => -7px
  // 16px font size / 24px icon size => -6px
  // 16px font size / 48px icon size => -18px
  // 32px font size / 24px icon size => -1px
  // 32px font size / 48px icon size => -13px
  // It seems there is no simple equation which leads to those results.
  // The most straight-forward solution is "font-size / 4 - icon-size / 2 + 2px" but even that is
  // off by 1px on larger font sizes. So we use 2px on 12px or 16px, but 3px on larger font sizes.
  --vertical-align: calc(.25em - .5 * var(--size) + var(--shift-correction))
  --shift-correction: calc(3px - min(1px, max(17px, 1em) - 1em))
  vertical-align: var(--vertical-align)

  background-color: var(--icon-color, currentColor)

  -webkit-mask: var(--mask)
  mask:  var(--mask)
