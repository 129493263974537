@import ../environment

.lca
  margin-block: var(--spacer-40)

  .scroller
    --fading-area-size-start: 0
    --fading-area-size-end: 120px
    --fading-area-offset-end: -30px
    --fading-border-area-size: 0

.lca--header
  margin-bottom: var(--spacer-16)

  +on-mobile-screens
    margin-bottom: var(--spacer-12)

.lca--name
  flex: 1 1 auto
  +bold

.lca--actions
  align-items: center
  flex: 0 0 auto
  display: flex
  gap: var(--spacer-12)
  height: 48px

.lca--body
  display: grid
  gap: var(--spacer-24)
  grid-auto-columns: minmax(var(--lca-tile-min-width), var(--lca-tile-max-width))
  grid-auto-flow: column
  justify-content: space-between

  +on-mobile-screens
    gap: var(--spacer-20)
    grid-auto-columns: minmax(var(--lca-tile-min-width-mobile), 1fr)

.lca--footer
  .lca.-current:not(:last-child) &
    margin-top: var(--spacer-40)
    border-bottom: var(--border-width-default) solid var(--ui-info)
