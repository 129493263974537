=scrollbar-hidden
  -ms-overflow-style: none
  -webkit-overflow-scrolling: touch
  white-space: nowrap
  +scrollbar-width(none)

  &::-webkit-scrollbar
    display: none

=scrollbar-width($width)
  @supports not selector(::-webkit-scrollbar)
    scrollbar-width: $width

=scrollbar-color($color)
  @supports not selector(::-webkit-scrollbar)
    scrollbar-color: $color
