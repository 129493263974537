@import ../environment

.confirmation-dialog
  margin: 2rem
  text-align: center

.confirmation-dialog--icon
  +icon(confirmation-dialog-icon, $size: 60)
  margin-bottom: 2rem

.confirmation-dialog--title
  margin-bottom: 2rem

.confirmation-dialog--message
  margin-bottom: 2rem
  color: $brand-stone
