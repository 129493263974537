@import ../environment

.sidebar-profile
  display: grid

  +when-sidebar-expanded
    --avatar-size: 40px
    align-items: center
    grid-template-areas: "avatar-start . name-and-role . logout-start" "avatar-end . company . logout-end"
    grid-template-columns: var(--avatar-size) var(--spacer-12) auto 1fr auto

  +when-sidebar-collapsed
    --avatar-size: 20px
    grid-template-areas: "avatar" "." "logout" "."
    grid-template-rows: var(--avatar-size) var(--spacer-16) auto var(--spacer-8)

.sidebar-profile--link
  +when-sidebar-expanded
    grid-column: avatar-start / avatar-end
    grid-row: avatar-start / avatar-end

  +when-sidebar-collapsed
    grid-area: avatar

.sidebar-profile--avatar
  +when-sidebar-expanded
    grid-column: avatar-start / avatar-end
    grid-row: avatar-start / avatar-end

  +when-sidebar-collapsed
    grid-area: avatar

  display: grid
  height: var(--avatar-size)
  place-items: center
  width: var(--avatar-size)

  border-radius: var(--max-border-radius)

  background-color: var(--avatar-background-color)
  color: var(--black)

.sidebar-profile--name,
.sidebar-profile--company
  +truncate-text-overflow-x
  padding-right: var(--spacer-4)

.sidebar-profile--name
  grid-area: name-and-role
  font-weight: 600

  +when-sidebar-collapsed
    display: none

.sidebar-profile--initials
  +button-typography
  line-height: var(--avatar-size)
  padding-top: 0.07em // center font vertically

.sidebar-profile--second-initial
  margin-left: 0.12em // Imitate letter spacing of a button, but keep visual horizontal centering

  +when-sidebar-collapsed
    display: none

.sidebar-profile--company
  grid-area: company
  font-size: var(--font-size-copy-small)

  +when-sidebar-collapsed
    display: none

.sidebar-profile--logout
  +when-sidebar-expanded
    grid-column: logout-start / logout-end
    grid-row: logout-start / logout-end

  +when-sidebar-collapsed
    grid-area: logout

.sidebar-profile--logout-button
  &:active,
  &:hover
    background-color: var(--link-hover-color)
