@import ../environment

.toggle-switch
  --toggle-switch-width: 48px
  --toggle-switch-height: 28px

  position: relative
  width: var(--toggle-switch-width)
  height: var(--toggle-switch-height)
  min-width: var(--toggle-switch-width)
  min-height: var(--toggle-switch-height)

.toggle-switch--checkbox
  display: none
  +only-in-tests
    display: block

.toggle-switch--switch
  --toggle-switch-track-border-radius: 100px
  --toggle-switch-track-border-width: 2px
  --toggle-switch-thumb-size: calc(var(--toggle-switch-height) - 4 * var(--toggle-switch-track-border-width))

  position: absolute
  width: 100%
  height: 100%
  border: var(--white) var(--toggle-switch-track-border-width) solid
  background-color: var(--background-level-1)
  display: block
  transition: all var(--transition-duration-default)
  border-radius: var(--toggle-switch-track-border-radius)
  cursor: pointer

  &::before
    position: absolute
    content: ""
    height: var(--toggle-switch-thumb-size)
    width: var(--toggle-switch-thumb-size)
    border-radius: var(--max-border-radius)
    display: block
    left: var(--toggle-switch-track-border-width)
    top: var(--toggle-switch-track-border-width)
    background-color: var(--white)
    transition: all var(--transition-duration-default)

.toggle-switch--checkbox
  &:checked
    & + .toggle-switch--switch
      background-color: var(--interaction-primary-active)

      &::before
        transform: translate(var(--toggle-switch-thumb-size), 0)

  &:disabled
    & + .toggle-switch--switch
      cursor: default
      border-color: var(--interaction-secondary-disabled)
      background-color: var(--background-level-2)

      &::before
        background-color: var(--interaction-secondary-disabled)

.toggle-switch:focus-visible
  outline: none

  & > .toggle-switch--switch
    border-color: var(--interaction-primary-active)
