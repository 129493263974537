@import ../environment

/* Assimilate Echart's tooltips with our bootstrap tooltips */

.echarts-tooltip
  padding: $tooltip-padding-y $tooltip-padding-x
  color: $tooltip-color
  background-color: $tooltip-bg
  border: none
  font-family: $font-family-sans-serif

.echarts-tooltip--headline
  +bold

.echarts-tooltip--value-container
  display: flex
  align-items: center
  justify-content: end
  width: 100%
  margin-top: var(--spacer-4)

.echarts-tooltip--value
  +bold
  margin-left: var(--spacer-20)
