@import ../environment

.lca-box-scroller
  +on-mobile-screens
    margin-bottom: calc(var(--content-padding-mobile) + var(--lca-tile-blur-radius))

  .scroller--content-container
    max-width: calc(var(--content-max-width) + 2 * var(--lca-tile-blur-radius))
    width: calc(100% + 2 * var(--lca-tile-blur-radius))

    margin: calc(-1 * var(--lca-tile-blur-radius))
    padding: var(--lca-tile-blur-radius)
