@import '../environment'

.lca-stage-config-section
  .label-wrap
    display: flex
    align-items: center
    gap: var(--spacer-8)
    .icon
      flex-shrink: 0

  & + &
    margin-top: var(--spacer-24)

.lca-stage-config-section--pef-stage-title
  margin-bottom: var(--spacer-12)
  color: var(--ui-secondary)
  +copy
