@import ../environment

.kpi-tile
  display: grid
  grid-auto-flow: column
  align-items: center
  justify-content: center
  gap: var(--spacer-16)
  padding: var(--spacer-16)
  border-radius: var(--kpi-tile-border-radius)
  background-color: rgba(var(--white-rgb), var(--transparency-level-0))

  &.-expanded
    grid-auto-flow: row
    gap: var(--spacer-32)
    padding: var(--spacer-32)
    border-radius: 0
    border-bottom: var(--kpi-active-tile-border-width) solid transparent
    background-color: transparent

    &:hover
      background: linear-gradient(to bottom, transparent, rgba(var(--white-rgb), var(--transparency-level-0)))
      color: var(--white) !important

  &.-expanded.-active
    border-bottom-color: var(--interaction-primary-active)
    background-image: linear-gradient(to bottom, transparent, rgba(var(--white-rgb), var(--transparency-level-0)))

  &:not(.-expanded):hover
    .kpi-tile--prefix,
    .kpi-tile--title
      color: var(--interaction-primary-active)
      transition: color var(--transition-duration-default)

.kpi-tile--prefix
  +h1

  .kpi-tile.-expanded &
    --kpi-tile-prefix-size: 155px

    width: var(--kpi-tile-prefix-size)
    height: var(--kpi-tile-prefix-size)
    line-height: var(--kpi-tile-prefix-size)
    text-align: center
    border: var(--border-width-active) solid var(--ui-highlight-primary-hover)
    border-radius: var(--max-border-radius)
    font-size: var(--kpi-expanded-tile-record-count-font-size)

  .kpi-tile.-expanded:not(.-active):hover &
    border-color: var(--white)
    transition: border-color var(--transition-duration-default)

  .kpi-tile.-expanded.-active &
    color: var(--interaction-primary-active)
    border-color: var(--interaction-primary-active)
