@import ../../environment

\:root
  --input-height: 50px
  --input-min-width: 200px
  --input-border-height: 2px
  --input-border-color: #{$brand-slate}
  --input-border-color-focus: var(--interaction-primary-active)
  --input-horizontal-padding: #{$input-horizontal-padding}
  --input-padding-top: #{$input-padding-top}
  --input-padding-bottom: #{$input-padding-bottom}

  --input-label-height: var(--font-size-copy)
  --input-shrinked-label-height: 14px

  --button-height: 50px
  --button-width-min: 177px
  --button-width-max: 660px
  --button-horizontal-padding: #{$button-horizontal-padding}
  --button-vertical-padding: #{$button-vertical-padding}
  --button-outline-border-width: 3px

  --wizard-stage-form-padding: var(--spacer-20)
